import { useStyletron } from 'baseui'
import { HeadingMedium, LabelLarge } from 'baseui/typography'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../../ApiProvider'
import { MachineT, MeasurementStatusT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'
import { DataTacho } from './data-tacho'

type StatusCardDataT = {
  header: string
  secondSectionStart?: number
  thirdSectionStart?: number
  maxValue: number
  progressValue: number | null
  unit: string
  reverseColor: boolean
  valueFormatOptions: Intl.NumberFormatOptions
}

type StatusProps = Readonly<{
  machine: MachineT | undefined
}>

export const Status: React.FC<StatusProps> = memo(({ machine }) => {
  const { t } = useTranslation()
  const { statusApi } = useApi()
  const [statusData, setStatusData] = useState<MeasurementStatusT[]>([])

  //**********************************************************************
  const processStepNumber = useMemo(
    () =>
      statusData.find(({ name }) => name === CURRENT_EVAPORATION_STEP)?.value ??
      0,
    [statusData]
  )
  const cleaningPcStepNumber = useMemo(
    () =>
      statusData.find(({ name }) => name === CURRENT_CLEANING_PC_STEP)?.value ??
      0,
    [statusData]
  )
  const cleaningEcStepNumber = useMemo(
    () =>
      statusData.find(({ name }) => name === CURRENT_CLEANING_EC_STEP)?.value ??
      0,
    [statusData]
  )
  //**********************************************************************

  const tachoData: Record<string, StatusCardDataT> = useMemo(() => {
    const entries = Object.entries(initialTachoData).map(([key, entry]) => {
      return [
        key,
        {
          ...entry,
          header: t(entry.header),
          progressValue:
            statusData.find(({ name }) => name === key)?.value ?? null,
        },
      ]
    })

    return Object.fromEntries(entries)
  }, [statusData, t])

  const { isLoading, startLoading, stopLoading } = useLoadIndicator()
  const loadStatusData = useCallback(() => {
    if (machine?.id) {
      startLoading()
      statusApi
        .getStatus(machine.id, [
          ...Object.keys(initialTachoData),
          CURRENT_EVAPORATION_STEP,
          CURRENT_CLEANING_PC_STEP,
          CURRENT_CLEANING_EC_STEP,
        ])
        .then((response) => response.data)
        .then((data) => setStatusData(data))
        .catch(() => setStatusData([]))
        .finally(stopLoading)
    } else {
      setStatusData([])
    }
  }, [machine?.id, startLoading, statusApi, stopLoading])
  useEffect(
    function periodicLoadStatusData() {
      loadStatusData()
      const interval = setInterval(loadStatusData, 10000)
      return () => clearInterval(interval)
    },
    [loadStatusData]
  )

  const [css, theme] = useStyletron()

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          height: '38px',
          borderRadius: '5px',
          backgroundColor: 'rgba(239,244,249,1)',
          marginBottom: theme.sizing.scale400,
        }}
      >
        <LabelLarge
          className={css({
            display: 'flex',
            fontSize: '22px',
            fontWeight: 'bolder',
            color: 'rgba(0,121,184,1)',
            alignSelf: 'center',
          })}
        >
          <div>{t('status.step')}</div>
          <div
            className={css({
              paddingLeft: theme.sizing.scale300,
              paddingRight: theme.sizing.scale300,
            })}
          >
            –
          </div>
          <div
            className={css({
              paddingRight: theme.sizing.scale100,
            })}
          >
            {t('status.step_eva')}:
          </div>
          <div
            className={css({
              color:
                processStepNumber === 0 ||
                (processStepNumber > 900 && processStepNumber <= 910)
                  ? theme.colors.negative
                  : theme.colors.positive300,
            })}
          >
            {processStepNumber}
          </div>
          <div
            className={css({
              paddingLeft: theme.sizing.scale300,
              paddingRight: theme.sizing.scale300,
            })}
          >
            •
          </div>
          <div
            className={css({
              paddingRight: theme.sizing.scale100,
            })}
          >
            {t('status.step_pc')}:
          </div>
          <div
            className={css({
              color:
                cleaningPcStepNumber === 0 ||
                (cleaningPcStepNumber > 900 && cleaningPcStepNumber <= 910)
                  ? theme.colors.negative
                  : theme.colors.positive300,
            })}
          >
            {cleaningPcStepNumber}
          </div>
          <div
            className={css({
              paddingLeft: theme.sizing.scale300,
              paddingRight: theme.sizing.scale300,
            })}
          >
            •
          </div>
          <div
            className={css({
              paddingRight: theme.sizing.scale100,
            })}
          >
            {t('status.step_ec')}:
          </div>
          <div
            className={css({
              color:
                cleaningEcStepNumber === 0 ||
                (cleaningEcStepNumber > 900 && cleaningEcStepNumber <= 910)
                  ? theme.colors.negative
                  : theme.colors.positive300,
            })}
          >
            {cleaningEcStepNumber}
          </div>
        </LabelLarge>
      </div>

      {statusData.length !== 0 ? (
        <div
          className={css({
            flexGrow: 1,
            flexShrink: 0,
            height: 0,
            overflow: 'auto',
            paddingTop: theme.sizing.scale800,
          })}
        >
          <div
            className={css({
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
              gap: '3em 3em',
            })}
          >
            {Object.values(tachoData).map((data, index) => (
              <DataTacho
                key={`tacho${index}`}
                header={data.header}
                secondSectionStart={data.secondSectionStart}
                thirdSectionStart={data.thirdSectionStart}
                maxValue={data.maxValue}
                progressValue={data.progressValue}
                unit={data.unit}
                reverseColor={data.reverseColor}
                valueFormatOptions={data.valueFormatOptions}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          className={css({
            flex: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          {isLoading && <HeadingMedium>{t('dashboard.loading')}</HeadingMedium>}
          {!isLoading && statusData.length === 0 && (
            <HeadingMedium>{t('dashboard.noData')}</HeadingMedium>
          )}
        </div>
      )}
    </div>
  )
})

const CURRENT_EVAPORATION_STEP = 'AktuellerSchrittEVA'
const CURRENT_CLEANING_PC_STEP = 'AktuellerSchrittReinigungVK'
const CURRENT_CLEANING_EC_STEP = 'AktuellerSchrittReinigungEK'
const initialTachoData: Record<string, StatusCardDataT> = {
  EVA01F201: {
    header: 'status.VCWasteWater',
    maxValue: 70,
    progressValue: null,
    unit: 'm³/h',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  EVA03S501: {
    header: 'status.VCSpeed',
    secondSectionStart: 3000,
    thirdSectionStart: 3500,
    maxValue: 3999,
    progressValue: null,
    unit: 'rpm',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 0 },
  },
  LeistungMVR: {
    header: 'status.VCPowerConsumption',
    secondSectionStart: 400,
    thirdSectionStart: 600,
    maxValue: 1000,
    progressValue: null,
    unit: 'kW',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 0 },
  },
  EVA03X501: {
    header: 'status.VCVibration',
    secondSectionStart: 25,
    thirdSectionStart: 35,
    maxValue: 100,
    progressValue: null,
    unit: 'µm/p',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  EVA04F209: {
    header: 'status.PFConcentrator',
    secondSectionStart: 0.5,
    thirdSectionStart: 1,
    maxValue: 4,
    progressValue: null,
    unit: 'm³/h',
    reverseColor: true,
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  PSG03P416: {
    header: 'status.PGen1',
    secondSectionStart: 16,
    thirdSectionStart: 18,
    maxValue: 30,
    progressValue: null,
    unit: 'bar',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  PSG06P516: {
    header: 'status.PGen2',
    secondSectionStart: 16,
    thirdSectionStart: 18,
    maxValue: 30,
    progressValue: null,
    unit: 'bar',
    reverseColor: false,
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
}
