import { yupResolver } from '@hookform/resolvers/yup'
import { KIND } from 'baseui/button'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { Select } from 'baseui/select'
import { Tag } from 'baseui/tag'
import { Tenant, User } from 'client'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

import { useApi } from '../../../ApiProvider'

type UserSchema = User & {
  password: string
  confirmPassword?: string
}

const AddUserDialog: React.FC<{
  isOpen: boolean
  onClose: (user: User) => void
  onCancel: () => void
}> = React.memo(({ isOpen, onClose, onCancel }) => {
  const [t] = useTranslation()
  const api = useApi()
  const userSchema: yup.ObjectSchema<UserSchema> = useMemo(() => {
    return yup.object({
      id: yup.string(),
      username: yup.string(),
      firstName: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.first_name')),
      lastName: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.last_name')),
      email: yup
        .string()
        .email('Must be a valid E-Mail Address')
        .required(t('validation.required'))
        .test('unused-email', t('validation.email_in_use'), (value) => {
          return api.usersApi
            .checkEmail(value)
            .then((response) => !response.data.inUse)
            .catch(() => false)
        })
        .label(t('settings.email')),
      enabled: yup.boolean().required(),
      roles: yup
        .array(yup.string().required())
        .min(1, t('validation.min'))
        .required(t('validation.required'))
        .label(t('settings.roles')),
      password: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.password')),
      confirmPassword: yup
        .string()
        .when('password', {
          is: '',
          otherwise: (schema) =>
            schema
              .required()
              .equals(
                [yup.ref('password')],
                t('validation.passwords_not_equal')
              ),
        })
        .label(t('settings.password2')),
      temporaryPassword: yup.boolean(),
      tenantId: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.tenant.tenant')),
    })
  }, [t, api.usersApi])

  const [tenants, setTenants] = useState<Tenant[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserSchema>({
    resolver: yupResolver(userSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      enabled: true,
      roles: ['user'],
      password: '',
      confirmPassword: '',
      temporaryPassword: false,
      tenantId: undefined,
    },
  })

  useEffect(() => {
    setIsLoading(true)
    api.tenantApi
      .listTenants()
      .then((response) => {
        setTenants(response.data)
      })
      .finally(() => setIsLoading(false))
  }, [api.tenantApi])

  return (
    <Modal isOpen={isOpen} closeable={false}>
      <form
        onSubmit={handleSubmit((user: UserSchema) => {
          onClose(user)
          reset()
        })}
      >
        <ModalHeader>{t('settings.add_user')}</ModalHeader>
        <ModalBody>
          <FormControl
            label={t('settings.tenant.tenant')}
            error={errors?.tenantId?.message}
          >
            <Controller
              name={'tenantId'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  isLoading={isLoading}
                  options={tenants}
                  onChange={({ value: tenant }) => {
                    onChange(tenant[0].id as string)
                  }}
                  onBlur={onBlur}
                  value={
                    value !== undefined
                      ? [
                          {
                            id: value,
                          },
                        ]
                      : undefined
                  }
                  labelKey={'name'}
                  error={!!errors?.tenantId}
                  placeholder={t('settings.tenant.tenant')}
                  clearable={false}
                  searchable={false}
                  deleteRemoves={false}
                  backspaceRemoves={false}
                  controlRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.first_name')}
            error={errors?.firstName?.message}
          >
            <Controller
              name={'firstName'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.first_name')}
                  value={value}
                  error={!!errors?.firstName}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.last_name')}
            error={errors?.lastName?.message}
          >
            <Controller
              name={'lastName'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t('settings.last_name')}
                  error={!!errors?.lastName}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.email')}
            error={errors?.email?.message}
          >
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    placeholder={t('settings.email')}
                    error={!!errors?.email}
                    inputRef={createCallbackRef(ref)}
                  />
                )
              }}
            />
          </FormControl>
          <FormControl
            label={t('settings.roles')}
            error={errors?.roles?.message}
          >
            <Controller
              name={'roles'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  options={[
                    { id: 'user', label: t('settings.user') },
                    { id: 'admin', label: t('settings.admin') },
                  ]}
                  onChange={({ value: roles }) => {
                    onChange(roles.map(({ id }) => id as string))
                  }}
                  onBlur={onBlur}
                  value={(value as ReadonlyArray<string>).map((id) => ({
                    id,
                  }))}
                  error={!!errors?.roles}
                  placeholder={t('settings.roles')}
                  clearable={false}
                  searchable={false}
                  deleteRemoves={false}
                  backspaceRemoves={false}
                  multi
                  controlRef={createCallbackRef(ref)}
                  overrides={{
                    Tag: {
                      component: (props: any) => {
                        return (
                          <Tag
                            {...props}
                            closeable={props.value.id !== 'user'}
                          />
                        )
                      },
                    },
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.password')}
            error={errors?.password?.message}
          >
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t('settings.password')}
                  type={'password'}
                  error={!!errors?.password || !!errors?.confirmPassword}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl error={errors?.confirmPassword?.message}>
            <Controller
              name={'confirmPassword'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t('settings.password2')}
                  type={'password'}
                  error={!!errors?.password || !!errors?.confirmPassword}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={'temporaryPassword'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  checked={value}
                  checkmarkType={STYLE_TYPE.default}
                  onChange={onChange}
                  onBlur={onBlur}
                  labelPlacement={LABEL_PLACEMENT.right}
                  inputRef={createCallbackRef(ref)}
                >
                  {t('settings.temporary_password')}
                </Checkbox>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={'enabled'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  checked={value}
                  checkmarkType={STYLE_TYPE.default}
                  onChange={onChange}
                  onBlur={onBlur}
                  labelPlacement={LABEL_PLACEMENT.right}
                  inputRef={createCallbackRef(ref)}
                >
                  {t('settings.user_enabled')}
                </Checkbox>
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => {
              onCancel()
              reset()
            }}
            type={'button'}
          >
            {t('cancel')}
          </ModalButton>
          <ModalButton type={'submit'}>{t('ok')}</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
})

export { AddUserDialog }
