import { yupResolver } from '@hookform/resolvers/yup'
import { Block } from 'baseui/block'
import { Button, KIND } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { HeadingXSmall } from 'baseui/typography'
import { Machine } from 'client'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

import { useApi } from '../../../ApiProvider'
import { useLoadIndicator } from '../../load-indicator'

export const MachineDetails: React.FC = React.memo(() => {
  const [machine, setMachine] = useState<Machine>()
  const [t] = useTranslation()
  const { locationId, machineId } = useParams()
  const { machineApi } = useApi()
  const { startLoading, stopLoading } = useLoadIndicator()
  const navigate = useNavigate()

  const machineSchema: yup.ObjectSchema<Machine> = useMemo(() => {
    return yup.object({
      id: yup.number(),
      name: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.machine.name')),
      locationId: yup
        .number()
        .required(t('validation.required'))
        .label(t('settings.machine.location')),
      deviceId: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.machine.device_id')),
      tenantId: yup.string(),
    })
  }, [t])

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<Machine>({
    resolver: yupResolver(machineSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      locationId: locationId ? parseInt(locationId) : undefined,
      deviceId: '',
    },
    values: machine,
  })

  useEffect(() => {
    if (machineId) {
      startLoading()
      machineApi
        .getMachine(parseInt(machineId))
        .then((response) => {
          setMachine(response.data)
        })
        .finally(stopLoading)
    }
  }, [machineApi, machineId, startLoading, stopLoading])

  if (!machine) {
    return null
  }

  return (
    <>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <HeadingXSmall marginTop={'scale300'} marginBottom={'scale600'}>
          {machine.name}
        </HeadingXSmall>
      </Block>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
        flex={'auto'}
        display={'flex'}
        flexDirection={'column'}
      >
        <form
          onSubmit={handleSubmit((machine: Machine) => {
            machineApi
              .updateMachine(machine.id!, machine)
              .then((response) => setMachine(response.data))
          })}
        >
          <FormControl
            label={t('settings.machine.name')}
            error={errors?.name?.message}
          >
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.machine.name')}
                  value={value}
                  error={!!errors?.name}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.machine.device_id')}
            error={errors?.deviceId?.message}
          >
            <Controller
              name={'deviceId'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.machine.device_id')}
                  value={value}
                  error={!!errors?.deviceId}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <Button
            disabled={!isValid || !isDirty}
            type={'submit'}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  marginRight: $theme.sizing.scale600,
                }),
              },
            }}
          >
            Save
          </Button>
          <Button
            onClick={() =>
              navigate({ pathname: '../../machines' }, { relative: 'path' })
            }
            kind={KIND.tertiary}
            type={'reset'}
          >
            Cancel
          </Button>
        </form>
      </Block>
    </>
  )
})
