import { BaseProvider } from 'baseui'
import React, { useEffect } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

import { Dashboard } from './components/dashboard'
import { Page } from './components/layout/page'
import { LoadIndicatorProvider } from './components/load-indicator'
import { Maintenance } from './components/maintenance'
import { Report } from './components/report'
import { Settings, Tenants, Users } from './components/settings'
import { LocationDetails } from './components/settings/locations/location-details'
import { MachineDetails } from './components/settings/machines/machine-details'
import { TenantDetails } from './components/settings/tenants/tenant-details'
import { TenantDetailsLayout } from './components/settings/tenants/tenant-details-layout'
import { LightTheme } from './themes'
import { useRoles } from './utils'

const engine = new Styletron()

const ProtectedRoute: React.FC = ({ children }) => {
  const { hasResourceRole } = useRoles()
  if (!hasResourceRole(['admin'], 'schrader-backend')) {
    return <Navigate to={'/dashboard'} replace={true} />
  }
  return children as React.ReactElement
}

const router = createHashRouter(
  createRoutesFromElements([
    <Route path={'/'} element={<Page />}>
      <Route
        index={true}
        element={<Navigate replace={true} to={'dashboard'} />}
      />
      <Route path={'dashboard'} element={<Dashboard />} />
      <Route path={'maintenance'} element={<Maintenance />} />
      <Route
        path={'report'}
        element={
          <ProtectedRoute>
            <Report />
          </ProtectedRoute>
        }
      />
      <Route
        path={'settings'}
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path={'settings/users'}
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path={'settings/tenants'}
        element={
          <ProtectedRoute>
            <Tenants />
          </ProtectedRoute>
        }
      />
      <Route
        path={'settings/tenants/:tenantId'}
        element={
          <ProtectedRoute>
            <TenantDetailsLayout />
          </ProtectedRoute>
        }
        handle={{
          crumb: 'settings.tenant.tenant_details',
        }}
      >
        <Route index={true} element={<Navigate to={'details'} />} />
        <Route
          path={'details'}
          element={
            <ProtectedRoute>
              <TenantDetails activeTab={'details'} />
            </ProtectedRoute>
          }
        />
        <Route
          path={'locations'}
          element={
            <ProtectedRoute>
              <TenantDetails activeTab={'locations'} />
            </ProtectedRoute>
          }
        />
        <Route
          path={'locations/:locationId'}
          element={<Navigate to={'details'} />}
        />
        <Route
          path={'locations/:locationId'}
          element={<Outlet />}
          handle={{
            crumb: 'settings.location.location_details',
          }}
        >
          <Route index={true} element={<Navigate to={'details'} />} />
          <Route
            path={'details'}
            element={
              <ProtectedRoute>
                <LocationDetails activeTab={'details'} />
              </ProtectedRoute>
            }
          />
          <Route
            path={'machines'}
            element={
              <ProtectedRoute>
                <LocationDetails activeTab={'machines'} />
              </ProtectedRoute>
            }
          />
          <Route
            path={'machines/:machineId'}
            element={
              <ProtectedRoute>
                <MachineDetails />
              </ProtectedRoute>
            }
            handle={{
              crumb: 'settings.machine.machine_details',
            }}
          />
        </Route>
      </Route>
    </Route>,
  ])
)

const App = () => {
  const auth = useAuth()

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect()
    }
  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
    auth,
  ])

  if (auth.activeNavigator) {
    return <div>Redirecting to Keycloak ...</div>
  }

  if (!auth.isAuthenticated) {
    return null
  }

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <LoadIndicatorProvider>
          <RouterProvider router={router} />
        </LoadIndicatorProvider>
      </BaseProvider>
    </StyletronProvider>
  )
}

export default App
