import { yupResolver } from '@hookform/resolvers/yup'
import { KIND } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { Tenant } from 'client'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

const AddTenantDialog: React.FC<{
  isOpen: boolean
  onClose: (tenant: Tenant) => void
  onCancel: () => void
}> = React.memo(({ isOpen, onClose, onCancel }) => {
  const [t] = useTranslation()
  const tenantSchema: yup.ObjectSchema<Tenant> = useMemo(() => {
    return yup.object({
      id: yup.string(),
      name: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.tenant.name')),
    })
  }, [t])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Tenant>({
    resolver: yupResolver(tenantSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    },
  })

  return (
    <Modal isOpen={isOpen} closeable={false}>
      <form
        onSubmit={handleSubmit((tenant: Tenant) => {
          onClose(tenant)
          reset()
        })}
      >
        <ModalHeader>{t('settings.tenant.add_tenant')}</ModalHeader>
        <ModalBody>
          <FormControl
            label={t('settings.tenant.name')}
            error={errors?.name?.message}
          >
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.tenant.name')}
                  value={value}
                  error={!!errors?.name}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => {
              onCancel()
              reset()
            }}
            type={'button'}
          >
            {t('cancel')}
          </ModalButton>
          <ModalButton type={'submit'}>{t('ok')}</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
})

export { AddTenantDialog }
