import './index.css'
import './i18n'

import axios from 'axios'
import { Configuration } from 'client'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { AuthProvider } from 'react-oidc-context'

import { ApiProvider } from './ApiProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'

type OidcConfigT = {
  authority: string
  client_id: string
}

let status = 'pending'
let result: OidcConfigT

const fetchOidcConfig = () => {
  const fetching = axios
    .get<OidcConfigT>(`${window.env.APP_BASE_PATH}/keycloak.json`)
    .then((response) => {
      return response.data
    })
    .then((success) => {
      status = 'fulfilled'
      result = success
    })
    .catch((e) => {
      status = 'rejected'
      result = e
    })

  return () => {
    if (status === 'pending') {
      throw fetching
    } else if (status === 'rejected') {
      throw result
    } else if (status === 'fulfilled') {
      return result
    }
  }
}

const oidcConfigData = fetchOidcConfig()

const AppWrapper: React.FC = () => {
  const oidcConfig = oidcConfigData()
  if (oidcConfig) {
    return (
      <AuthProvider
        {...oidcConfig}
        redirect_uri={window.location.href}
        onSigninCallback={(user) => {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname + window.location.hash
          )
        }}
      >
        <ApiProvider
          configuration={
            new Configuration({
              basePath: `${window.env.APP_BASE_PATH}/api/v1`,
            })
          }
        >
          <App />
        </ApiProvider>
      </AuthProvider>
    )
  }
  return null
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading OIDC configuration ...</div>}>
      <AppWrapper />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
