import jwtDecode from 'jwt-decode'
import { useCallback } from 'react'
import { useAuth } from 'react-oidc-context'

export const useScope = () => {
  const auth = useAuth()
  return {
    hasScope: useCallback(
      (scopes: string[]) =>
        scopes.some((scope) => auth.user?.scopes?.includes(scope)),
      [auth.user]
    ),
  }
}

export const useRoles = () => {
  const auth = useAuth()
  let decodedToken: undefined | any = undefined
  if (auth.user) {
    decodedToken = jwtDecode(auth.user.access_token)
  }
  return {
    hasResourceRole: useCallback(
      (roles: string[], clientId: string) => {
        const resourceRoles = decodedToken?.resource_access[clientId]?.roles
        if (resourceRoles !== undefined) {
          return roles.some((role) => resourceRoles?.includes(role))
        }
        return false
      },
      [decodedToken]
    ),
    hasRealmRole: useCallback(
      (roles: string[]) => {
        const resourceRoles = decodedToken?.realm_access.roles
        return roles.some((role) => resourceRoles?.includes(role))
      },
      [decodedToken]
    ),
  }
}
