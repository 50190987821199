"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Schrader Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AggregationType = void 0;
/**
 * aggregation type
 * @export
 * @enum {string}
 */
exports.AggregationType = {
    MIN: 'MIN',
    MAX: 'MAX',
    AVG: 'AVG',
    SUM: 'SUM'
};
