import { mdiOpenInNew } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { Button, KIND, SIZE } from 'baseui/button'
import { LabelMedium } from 'baseui/typography'
import { MeasurementsMultiAggregate } from 'client'
import React, { FC, memo, useMemo } from 'react'
import { AutoSizer } from 'react-virtualized'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

import { useRoles } from '../../utils'

type ChartProps = Readonly<{
  header: string
  isGridViewEnabled: boolean
  data: Array<MeasurementsMultiAggregate>
  dataKey: string
  barColor: string | undefined
  valueFormatter?: (value: ValueType) => ValueType
  nameFormatter?: (name: NameType) => NameType
}>

export const Chart: FC<ChartProps> = memo((props) => {
  const {
    header,
    isGridViewEnabled,
    data,
    dataKey,
    barColor = 'rgba(0,121,184,1)',
    valueFormatter,
    nameFormatter,
  } = props

  const { hasResourceRole } = useRoles()
  const barSize = useMemo(() => {
    if (isGridViewEnabled && data.length < 10) {
      return 20
    } else if (!isGridViewEnabled && data.length < 20) {
      return 40
    }
  }, [data.length, isGridViewEnabled])

  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: `0px 4px 8px rgba(0,0,0,0.2)`,
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
          height: theme.sizing.scale1000,
        })}
      >
        <LabelMedium
          style={{
            display: 'flex',
            width: '100%',
            height: theme.sizing.scale1200,
            paddingTop: theme.sizing.scale400,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {header}
        </LabelMedium>
        {hasResourceRole(['user'], 'dataindustry') && (
          <Button
            $as='a'
            href={window.env.DATAINDUSTRY_URL}
            target='_blank'
            className={css({
              width: '30px',
              height: '30px',
            })}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            <Icon path={mdiOpenInNew} size={'16px'} />
          </Button>
        )}
      </div>
      <div
        className={css({
          flex: 'auto',
        })}
      >
        <AutoSizer>
          {({ width, height }) => (
            <BarChart width={width} height={height} data={data}>
              <CartesianGrid strokeDasharray='1 4' />
              <XAxis dataKey='timestamp' />
              <YAxis />
              <Tooltip
                formatter={(value, name) => [
                  valueFormatter?.(value) ?? value,
                  nameFormatter?.(name) ?? name,
                ]}
              />

              <Bar dataKey={dataKey} fill={barColor} barSize={barSize} />
            </BarChart>
          )}
        </AutoSizer>
      </div>
    </div>
  )
})
