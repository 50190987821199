import { yupResolver } from '@hookform/resolvers/yup'
import { KIND } from 'baseui/button'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { User } from 'client'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

export type PasswordSchema = {
  password: string
  confirmPassword?: string
  temporaryPassword?: boolean
}

const PasswordDialog: React.FC<{
  user: User
  isOpen: boolean
  onClose: (user: User) => void
  onCancel: () => void
}> = React.memo(({ user, isOpen, onClose, onCancel }) => {
  const [t] = useTranslation()
  const passwordSchema: yup.ObjectSchema<PasswordSchema> = useMemo(() => {
    return yup.object({
      password: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.password')),
      confirmPassword: yup
        .string()
        .when('password', {
          is: '',
          otherwise: (schema) =>
            schema
              .required()
              .equals(
                [yup.ref('password')],
                t('validation.passwords_not_equal')
              ),
        })
        .label(t('settings.password2')),
      temporaryPassword: yup.boolean(),
    })
  }, [t])
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PasswordSchema>({
    resolver: yupResolver(passwordSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
      temporaryPassword: false,
    },
  })

  return (
    <Modal isOpen={isOpen} closeable={false}>
      <form
        onSubmit={handleSubmit((password: PasswordSchema) => {
          onClose({
            ...user,
            password: password.password,
            temporaryPassword: password.temporaryPassword,
          })
          reset()
        })}
      >
        <ModalHeader>{t('settings.reset_password')}</ModalHeader>
        <ModalBody>
          <FormControl
            label={t('settings.new_password')}
            error={errors?.password?.message}
          >
            <Controller
              name={'password'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t('settings.new_password')}
                  type={'password'}
                  error={!!errors?.password || !!errors?.confirmPassword}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl error={errors?.confirmPassword?.message}>
            <Controller
              name={'confirmPassword'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder={t('settings.new_password2')}
                  type={'password'}
                  error={!!errors?.password || !!errors?.confirmPassword}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name={'temporaryPassword'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  checked={value}
                  checkmarkType={STYLE_TYPE.default}
                  onChange={onChange}
                  onBlur={onBlur}
                  labelPlacement={LABEL_PLACEMENT.right}
                  inputRef={createCallbackRef(ref)}
                >
                  {t('settings.temporary_password')}
                </Checkbox>
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => {
              onCancel()
              reset()
            }}
            type={'button'}
          >
            {t('cancel')}
          </ModalButton>
          <ModalButton type={'submit'}>{t('ok')}</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
})

export { PasswordDialog }
