import { mdiDotsGrid, mdiMenu } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { Button, KIND } from 'baseui/button'
import { HeadingMedium } from 'baseui/typography'
import { Aggregate, AggregationMode, MeasurementsMultiAggregate } from 'client'
import { DateTime, DateTimeFormatOptions, Interval } from 'luxon'
import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../../ApiProvider'
import { LocationT, MachineT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'
import { Chart } from './chart'
import {
  TimeRange,
  TimerangeSelector,
  useDataInterval,
} from './timerange-selector'

type KPIProps = Readonly<{
  location: LocationT | undefined
  machine: MachineT | undefined
}>

export const KPI: FC<KPIProps> = memo(({ location, machine }) => {
  const { kpiApi } = useApi()

  const [timeRange, setTimeRange] = useState<TimeRange | Interval>(
    TimeRange.LAST_WEEK
  )
  const dataInterval = useDataInterval(timeRange, location?.timezone)

  const aggregationMode = useMemo(() => {
    if (Interval.isInterval(timeRange)) {
      return AggregationMode.DAYS
    }
    switch (timeRange) {
      case TimeRange.LAST_DAY:
        return AggregationMode.HOURS
      case TimeRange.LAST_WEEK:
      case TimeRange.LAST_MONTH:
        return AggregationMode.DAYS
      case TimeRange.LAST_YEAR:
        return AggregationMode.MONTHS
      default:
        return AggregationMode.HOURS
    }
  }, [timeRange])
  const [rawAggregates, setRawAggregates] = useState<
    ReadonlyArray<MeasurementsMultiAggregate>
  >([])

  const { isLoading, startLoading, stopLoading } = useLoadIndicator()
  useEffect(
    function loadRawAggregates() {
      const start = dataInterval.start?.toISO()
      const end = dataInterval.end?.toISO()
      if (machine?.id && start && end) {
        startLoading()
        kpiApi
          .getKpiMultiAggregates(
            machine.id,
            start,
            end,
            aggregationMode,
            CHART_SETTINGS.map(({ key }) => key)
          )
          .then((response) => response.data)
          .then(setRawAggregates)
          .finally(stopLoading)

        return () => setRawAggregates([])
      }
    },
    [
      aggregationMode,
      dataInterval.end,
      dataInterval.start,
      kpiApi,
      machine?.id,
      startLoading,
      stopLoading,
    ]
  )

  const timestampFormat: DateTimeFormatOptions = useMemo(() => {
    switch (aggregationMode) {
      case 'HOURS':
        return DateTime.TIME_SIMPLE
      case 'DAYS':
        return { month: 'long', day: 'numeric' }
      case 'MONTHS':
        return { month: 'long', year: 'numeric' }
      default:
        return DateTime.DATE_FULL
    }
  }, [aggregationMode])
  const aggregates = useMemo(
    () =>
      [...rawAggregates]
        .sort((l, r) => l.timestamp.localeCompare(r.timestamp))
        .map((agg) => ({
          ...agg,
          timestamp: DateTime.fromISO(agg.timestamp).toLocaleString(
            timestampFormat
          ),
        })),
    [rawAggregates, timestampFormat]
  )

  const [gridViewEnabled, setGridViewEnabled] = useState(true)

  const [css] = useStyletron()
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '50px',
          flexDirection: 'row',
        }}
      >
        <div
          className={css({
            flex: 'auto',
            display: 'flex',
            flexDirection: 'row',
          })}
        >
          <TimerangeSelector
            availableTimeRanges={[
              TimeRange.LAST_WEEK,
              TimeRange.LAST_MONTH,
              TimeRange.LAST_YEAR,
              'CUSTOM',
            ]}
            selectedTimeRange={timeRange}
            onChange={setTimeRange}
          />
        </div>

        <Button
          onClick={() => setGridViewEnabled(!gridViewEnabled)}
          kind={KIND.tertiary}
        >
          {gridViewEnabled ? (
            <Icon path={mdiMenu} size={1} />
          ) : (
            <Icon path={mdiDotsGrid} size={1} />
          )}
        </Button>
      </div>

      {aggregates.length !== 0 ? (
        <div
          className={css({
            flexGrow: 1,
            flexShrink: 0,
            height: 0,
            overflow: 'auto',
          })}
        >
          <div
            className={css({
              ...(gridViewEnabled
                ? {
                    gridTemplateColumns:
                      'repeat(auto-fill, minmax(400px, 1fr))',
                    gridAutoRows: '200px',
                  }
                : {
                    gridAutoRows: '200px',
                  }),
              display: 'grid',
              paddingTop: '0.5em',
              paddingLeft: '0.5em',
              paddingBottom: '0.5em',
              paddingRight: '0.5em',
              gap: '1em',
              overflow: 'hidden',
            })}
          >
            {CHART_SETTINGS.map((setting) => (
              <Chart
                key={setting.key}
                header={t(setting.caption)}
                isGridViewEnabled={gridViewEnabled}
                data={aggregates}
                dataKey={`values.${setting.key}.${setting.aggregationMode}`}
                barColor={setting.color}
                valueFormatter={(value) =>
                  Number.parseFloat(value.toString()).toLocaleString(
                    undefined,
                    setting.valueFormatOptions
                  )
                }
                nameFormatter={() => t(setting.caption).toString()}
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          className={css({
            flex: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <div
            className={css({
              flex: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            {isLoading && (
              <HeadingMedium>{t('dashboard.loading')}</HeadingMedium>
            )}
            {!isLoading && aggregates.length === 0 && (
              <HeadingMedium>{t('dashboard.noData')}</HeadingMedium>
            )}
          </div>
        </div>
      )}
    </div>
  )
})

const CHART_KEYS = ['VAPOUR_COMPRESSOR_EFFICIENCY'] as const
type ChartKeyT = (typeof CHART_KEYS)[number]
type ChartSettingT = {
  key: ChartKeyT
  displayName?: string
  caption: string
  color: string
  aggregationMode: keyof Aggregate
  valueFormatOptions?: Intl.NumberFormatOptions
}
const CHART_SETTINGS: ReadonlyArray<ChartSettingT> = [
  {
    key: 'VAPOUR_COMPRESSOR_EFFICIENCY',
    caption: 'kpi.nameChart1',
    color: 'rgba(0,121,184,1)',
    aggregationMode: 'avg',
  },
] as const
