import {
  mdiAccount,
  mdiCog,
  mdiFactory,
  mdiFileChartOutline,
  mdiLogout,
  mdiMessageTextOutline,
  mdiMonitor,
  mdiOfficeBuildingMarker,
  mdiTools,
  mdiTrendingUp,
} from '@mdi/js'
import { Icon as MdiIcon } from '@mdi/react'
import { styled, useStyletron, withStyle } from 'baseui'
import { Combobox } from 'baseui/combobox'
import { StyledNavigationItem as NavigationItem } from 'baseui/header-navigation'
import { ChevronLeft, ChevronRight } from 'baseui/icon'
import { StyledLink } from 'baseui/link'
import { ListItem, ListItemLabel } from 'baseui/list'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import React, { FC, Fragment, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { NavLink, useLocation } from 'react-router-dom'

import SchraderLogoLarge from '../../assets/logo-large.png'
import SchraderLogoSmall from '../../assets/logo-small.png'
import { useRoles, useScope } from '../../utils'
import { LocationT, MachineT } from '../backend-data'

type SideNavigationProps = Readonly<{
  availableLocations: ReadonlyArray<LocationT>
  availableMachines: ReadonlyArray<MachineT>
  selectedMachine: MachineT | undefined
  language: string
  onChangeMachine: (machine: MachineT | undefined) => void
  onChangeLanguage: (label: string) => void
}>

export const SideNavigation: React.FC<SideNavigationProps> = memo((props) => {
  const {
    availableLocations,
    availableMachines,
    selectedMachine,
    language,
    onChangeMachine,
    onChangeLanguage,
  } = props

  const [css] = useStyletron()

  const { t } = useTranslation()
  const [isextended, setIsExtended] = useState(false)
  const auth = useAuth()
  const { hasResourceRole } = useRoles()
  const { hasScope } = useScope()
  const { pathname } = useLocation()
  const machineSelectorEnabled = useMemo(
    () => pathname.startsWith('/dashboard'),
    [pathname]
  )

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        width: !isextended ? '80px' : '250px',
        minWidth: !isextended ? '80px' : '250px',
        transition: 'width 0.25s ease-in-out',
        color: '#000000',
        backgroundColor: 'rgba(239,244,249,1)',
      })}
    >
      <StyledNavItem>
        {!isextended ? <LogoSmall /> : <LogoLarge />}
      </StyledNavItem>

      <div
        className={css({
          marginTop: '2em',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <StyledNavItem>
          {machineSelectorEnabled ? (
            <StatefulPopover
              content={
                <MachineSelector
                  availableLocations={availableLocations}
                  availableMachines={availableMachines}
                  selectedMachine={selectedMachine}
                  onChange={onChangeMachine}
                />
              }
              placement={PLACEMENT.right}
              showArrow
              returnFocus
              autoFocus
            >
              <div className={css({ display: 'flex', gap: '1em' })}>
                <StyledMdiIcon size={1.25} path={mdiOfficeBuildingMarker} />
                {isextended && selectedMachine?.name}
              </div>
            </StatefulPopover>
          ) : (
            <div
              className={css({
                display: 'flex',
                gap: '1em',
                color: 'lightgray',
              })}
            >
              <StyledMdiIcon
                size={1.25}
                style={{ color: 'inherit' }}
                path={mdiOfficeBuildingMarker}
              />
              {isextended && selectedMachine?.name}
            </div>
          )}
        </StyledNavItem>
      </div>

      <div
        className={css({
          marginTop: '2em',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <StyledNavItem>
          <NavigationLink
            to={'/dashboard'}
            mdiIconPath={mdiMonitor}
            extendedNavigation={isextended}
          >
            {t('navigation.dashboard')}
          </NavigationLink>
        </StyledNavItem>

        {hasResourceRole(['user'], 'maintapp') && (
          <StyledNavItem>
            <NavigationLink
              to={'/maintenance'}
              mdiIconPath={mdiTools}
              extendedNavigation={isextended}
            >
              {t('navigation.maint')}
            </NavigationLink>
          </StyledNavItem>
        )}

        {hasResourceRole(['user'], 'dataindustry') && (
          <StyledNavItem>
            <NavigationLink
              to={window.env.DATAINDUSTRY_URL}
              mdiIconPath={mdiTrendingUp}
              extendedNavigation={isextended}
              external
            >
              {t('navigation.trend')}
            </NavigationLink>
          </StyledNavItem>
        )}

        {hasScope(['report:create']) && (
          <StyledNavItem>
            <NavigationLink
              to={'/report'}
              mdiIconPath={mdiFileChartOutline}
              extendedNavigation={isextended}
            >
              {t('navigation.report')}
            </NavigationLink>
          </StyledNavItem>
        )}
      </div>

      <div
        className={css({
          flex: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          paddingBottom: '50px',
        })}
      >
        <StyledNavItem>
          {!isextended ? (
            <StatefulPopover
              content={() => (
                <Combobox
                  value={language}
                  overrides={{ Root: { style: { paddingRight: '15px' } } }}
                  size='compact'
                  onChange={(nextValue) => {
                    onChangeLanguage(nextValue)
                  }}
                  options={[
                    { label: 'Deutsch', id: 'de' },
                    { label: 'English', id: 'en' },
                    { label: 'Portuguese', id: 'pt' },
                  ]}
                  mapOptionToString={(option) => option.label}
                />
              )}
              placement={PLACEMENT.right}
              showArrow
              returnFocus
              autoFocus
            >
              <StyledMdiIcon
                size={1.25}
                className={css({})}
                path={mdiMessageTextOutline}
              />
            </StatefulPopover>
          ) : (
            <div
              className={css({
                display: 'flex',
                flexDirection: 'row',
                gap: '0em',
                alignItems: 'center',
              })}
            >
              <StyledMdiIcon
                size={1.25}
                className={css({})}
                path={mdiMessageTextOutline}
              />
              <Combobox
                value={language}
                overrides={{ Root: { style: { paddingRight: '15px' } } }}
                size='compact'
                onChange={(nextValue) => {
                  onChangeLanguage(nextValue)
                }}
                options={[
                  { label: 'Deutsch', id: 'de' },
                  { label: 'English', id: 'en' },
                  { label: 'Portuguese', id: 'pt' },
                ]}
                mapOptionToString={(option) => option.label}
              />
            </div>
          )}
        </StyledNavItem>

        <StyledNavItem>
          <StatefulPopover
            content={<AccountActions />}
            placement={PLACEMENT.right}
            showArrow
            returnFocus
            autoFocus
          >
            <div
              className={css({
                display: 'flex',
                gap: '1em',
                cursor: 'pointer',
              })}
            >
              <StyledMdiIcon
                size={1.25}
                className={css({})}
                path={mdiAccount}
              />
              {isextended && <span>{auth.user?.profile.name ?? '---'}</span>}
            </div>
          </StatefulPopover>
        </StyledNavItem>

        {hasResourceRole(['admin'], 'schrader-backend') && (
          <StyledNavItem>
            <NavigationLink
              to={'/settings'}
              mdiIconPath={mdiCog}
              extendedNavigation={isextended}
            >
              {t('navigation.settings')}
            </NavigationLink>
          </StyledNavItem>
        )}
      </div>

      {!isextended ? (
        <ChevronRight
          size={25}
          color='#0079B8'
          className={css({
            paddingBottom: '5px',
            alignSelf: 'center',
            color: 'black',
            cursor: 'pointer',
          })}
          onClick={() => {
            setIsExtended(!isextended)
          }}
        />
      ) : (
        <ChevronLeft
          size={25}
          color='#0079B8'
          className={css({
            paddingBottom: '5px',
            alignSelf: 'flex-end',
            color: 'black',
            cursor: 'pointer',
          })}
          onClick={() => {
            setIsExtended(!isextended)
          }}
        />
      )}
    </div>
  )
})

interface MachineSelectorProps
  extends Readonly<{
    availableLocations: ReadonlyArray<LocationT>
    availableMachines: ReadonlyArray<MachineT>
    selectedMachine: MachineT | undefined
    onChange: (machine: MachineT | undefined) => any
  }> {}

const MachineSelector: FC<MachineSelectorProps> = memo((props) => {
  const { availableLocations, availableMachines, selectedMachine, onChange } =
    props

  const locationsById = useMemo(
    () =>
      new Map(availableLocations.map((location) => [location.id, location])),
    [availableLocations]
  )
  const machinesByLocation = useMemo(
    () =>
      Array.from(
        availableMachines.reduce((groups, machine) => {
          const { locationId } = machine
          const location = locationsById.get(locationId)
          if (location) {
            const group = groups.get(location)
            if (group) {
              group.push(machine)
            } else {
              groups.set(location, [machine])
            }
          }
          return groups
        }, new Map<LocationT, MachineT[]>())
      ),
    [locationsById, availableMachines]
  )

  const [css, theme] = useStyletron()
  return (
    <ul className={css({ padding: 0 })}>
      <ListItem
        overrides={{
          Root: {
            style: {
              cursor: 'pointer',
              ...(!selectedMachine
                ? { backgroundColor: theme.colors.accent50 }
                : {}),
            },
            props: { onClick: () => onChange(undefined) },
          },
        }}
      >
        <ListItemLabel>Overview</ListItemLabel>
      </ListItem>

      {machinesByLocation.map(([location, machines]) => (
        <Fragment key={location.id}>
          <ListItem>
            <ListItemLabel>{location.name}</ListItemLabel>
          </ListItem>
          {machines.map((machine) => (
            <ListItem
              key={`${location.id}-${machine.id}`}
              sublist
              artwork={MachineIcon}
              overrides={{
                Root: {
                  style: {
                    cursor: 'pointer',
                    ...(machine === selectedMachine
                      ? { backgroundColor: theme.colors.accent50 }
                      : {}),
                  },
                  props: {
                    onClick: () => {
                      onChange(machine)
                    },
                  },
                },
              }}
            >
              <ListItemLabel sublist>Machine: {machine.name}</ListItemLabel>
            </ListItem>
          ))}
        </Fragment>
      ))}
    </ul>
  )
})
const MachineIcon: React.FC<{ size?: string | number }> = memo(() => {
  return <StyledMdiIcon path={mdiFactory} />
})

const AccountActions: FC = memo(() => {
  const auth = useAuth()
  return (
    <ListItem
      artwork={LogoutIcon}
      overrides={{
        Root: {
          style: { cursor: 'pointer' },
          props: {
            onClick: () => {
              auth.signoutRedirect({
                post_logout_redirect_uri: window.location.href,
              })
            },
          },
        },
      }}
    >
      <ListItemLabel>Logout</ListItemLabel>
    </ListItem>
  )
})
const LogoutIcon: FC<{ size?: string | number }> = memo(() => {
  return <StyledMdiIcon path={mdiLogout} />
})

const LogoSmall: React.FC = React.memo(() => {
  const [css] = useStyletron()
  return (
    <img
      src={SchraderLogoSmall}
      alt={'Schrader'}
      className={css({
        height: '2em',
        width: '2em',
        marginTop: '0.75em',
        marginBottom: '0.25em',
      })}
    />
  )
})

const LogoLarge: React.FC = React.memo(() => {
  const [css] = useStyletron()
  return (
    <img
      src={SchraderLogoLarge}
      alt={'Schrader'}
      className={css({
        height: '2em',
        width: '200px',
        marginTop: '0.75em',
        marginBottom: '0.25em',
      })}
    />
  )
})

type NavigationLinkProps = Readonly<{
  to: string
  mdiIconPath: string
  extendedNavigation: boolean
  external?: boolean
}>
const NavigationLink: React.FC<NavigationLinkProps> = React.memo((props) => {
  const { to, children, mdiIconPath, extendedNavigation, external } = props
  const location = useLocation()
  const isActive = location.pathname.startsWith(to)

  const [css] = useStyletron()
  return external ? (
    <StyledLink href={to} target='_blank'>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5em',
          alignItems: 'center',
        })}
      >
        {' '}
        <StyledMdiIcon
          path={mdiIconPath}
          className={css({
            color: isActive ? '#F82523' : 'grey',
          })}
        />
        {extendedNavigation ? children : null}
      </div>
    </StyledLink>
  ) : (
    <NavLink
      to={to}
      className={css({
        color: isActive ? '#F82523' : 'grey',
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 'bold',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5em',
          alignItems: 'center',
        })}
      >
        {' '}
        <StyledMdiIcon
          path={mdiIconPath}
          className={css({
            color: isActive ? '#F82523' : 'grey',
          })}
        />
        {extendedNavigation ? children : null}
      </div>
    </NavLink>
  )
})

const StyledNavItem = withStyle(NavigationItem, {
  display: 'flex',
  alignSelf: 'left',
  paddingLeft: '25px',
  paddingTop: '15px',
  paddingRight: '25px',
  paddingBottom: '15px',
  gap: '0.5em',
})

const StyledMdiIcon = styled(MdiIcon, () => ({
  height: '1.25em',
  fontSize: '1.25em',
  color: 'gray',
}))
