import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox'
import { SIZE, TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { toaster } from 'baseui/toast'
import { User } from 'client'
import { DateTime } from 'luxon'
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../../ApiProvider'
import { AnalyticErrorT, MachineT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'
import {
  TimeRange,
  TimerangeSelector,
  TimeRangeT,
  useDataInterval,
} from './timerange-selector'

type AnalyticErrorsHistoryProps = Readonly<{
  machine: MachineT
}>

export const AnalyticErrorsHistory: FC<AnalyticErrorsHistoryProps> = memo(
  ({ machine }) => {
    const { analyticErrApi, usersApi } = useApi()
    const { startLoading, stopLoading } = useLoadIndicator()

    const [showClosedErrors, setShowClosedErrors] = useState(false)
    const [timeRange, setTimeRange] = useState<TimeRangeT>(TimeRange.LAST_DAY)
    const dataInterval = useDataInterval(timeRange, machine.location?.timezone)

    const [errors, setErrors] = useState<AnalyticErrorT[]>([])
    useEffect(
      function loadInitialErrorList() {
        if (machine.id) {
          startLoading()
          const promise = showClosedErrors
            ? analyticErrApi.getClosedAnalyticErrors(
                machine.id,
                dataInterval.start?.toISO() ?? '',
                dataInterval.end?.toISO() ?? ''
              )
            : analyticErrApi.getOpenAnalyticErrors(machine.id)

          promise
            .then((response) => response.data)
            .then(setErrors)
            .catch(() => {
              setErrors([])
              toaster.negative('load errors failed', {
                closeable: true,
                autoHideDuration: 3000,
              })
            })
            .finally(() => stopLoading())

          return () => setErrors([])
        }
      },
      [
        analyticErrApi,
        dataInterval.end,
        dataInterval.start,
        machine.id,
        showClosedErrors,
        startLoading,
        stopLoading,
      ]
    )

    const [users, setUsers] = useState<ReadonlyArray<User>>([])
    useEffect(
      function loadUsers() {
        const userIds = Array.from(
          new Set(
            errors.map((analyticError) => analyticError.ackBy).filter(Boolean)
          )
        )

        startLoading()
        Promise.all(
          userIds.map((userId) =>
            usersApi.getUser(userId).then((response) => response.data)
          )
        )
          .then(setUsers)
          .catch(() => {
            setUsers([])
            toaster.negative('load users failed', {
              closeable: true,
              autoHideDuration: 3000,
            })
          })
          .finally(() => stopLoading())
      },
      [errors, startLoading, stopLoading, usersApi]
    )

    const setAck = useCallback(
      (errorsToAck: ReadonlyArray<AnalyticErrorT>) => {
        if (machine.id) {
          analyticErrApi
            .ackAnalyticErrors(
              machine.id,
              errorsToAck.map(({ id }) => id)
            )
            .then((response) => response.data)
            .then((savedErrors) =>
              setErrors((prev) => {
                const savedIds = new Set(savedErrors.map(({ id }) => id))
                return [...prev.filter((e) => !savedIds.has(e.id))]
              })
            )
        }
      },
      [analyticErrApi, machine.id]
    )

    const [css] = useStyletron()
    const { t } = useTranslation()
    return (
      <div
        className={css({
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        })}
      >
        <div
          className={css({
            display: 'flex',
            gap: '1em',
            minHeight: '5em',
            alignItems: 'center',
          })}
        >
          <Checkbox
            checked={showClosedErrors}
            onChange={(e) => setShowClosedErrors(e.currentTarget.checked)}
            checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            {showClosedErrors
              ? t('dashboard.analyticerrors.closed')
              : t('dashboard.analyticerrors.open')}
          </Checkbox>
          {showClosedErrors && (
            <TimerangeSelector
              availableTimeRanges={[
                TimeRange.LAST_DAY,
                TimeRange.LAST_WEEK,
                TimeRange.LAST_MONTH,
                'CUSTOM',
              ]}
              selectedTimeRange={timeRange}
              onChange={setTimeRange}
            />
          )}
          {!showClosedErrors && (
            <Button
              onClick={() => setAck(errors)}
              disabled={!errors.length}
              className={css({ marginLeft: 'auto' })}
            >
              {t('dashboard.analyticerrors.ackAll')}
            </Button>
          )}
        </div>
        <div
          className={css({
            flexGrow: 1,
            height: 0,
            overflowY: 'auto',
          })}
        >
          <ErrorList
            errors={errors}
            users={users}
            setAck={showClosedErrors ? undefined : setAck}
          />
        </div>
      </div>
    )
  }
)

enum ColumnId {
  ERROR = 'ERROR',
  STARTED_AT = 'STARTED_AT',
  STOPPED_AT = 'STOPPED_AT',
  ACK_AT = 'ACK_AT',
  ACK_BY = 'ACK_BY',
}

interface ErrorListProps
  extends Readonly<{
    errors: ReadonlyArray<AnalyticErrorT>
    users: ReadonlyArray<User>
    setAck?: (errors: ReadonlyArray<AnalyticErrorT>) => any
  }> {}

const ErrorList: FC<ErrorListProps> = memo((props) => {
  const { errors, users, setAck } = props
  const { t, i18n } = useTranslation()

  const formatDateTime = useCallback(
    (dateTimeISO: string) => {
      return DateTime.fromISO(dateTimeISO).toLocaleString(
        {
          dateStyle: 'short',
          timeStyle: 'short',
        },
        { locale: i18n.language }
      )
    },
    [i18n.language]
  )
  const formatError = useCallback(
    (error: string) => t(`analyticalStatus.${error}.active`),
    [t]
  )
  const formatUserId = useCallback(
    (userId: string) =>
      users
        .filter(({ id }) => id === userId)
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .find(Boolean) ?? userId,
    [users]
  )

  const [sortAsc, setSortAsc] = useState(false)
  const objectSelector = useMemo<
    (l: AnalyticErrorT, r: AnalyticErrorT) => [AnalyticErrorT, AnalyticErrorT]
  >(
    () => (l, r) => {
      return sortAsc ? [l, r] : [r, l]
    },
    [sortAsc]
  )
  const [sortColumnId, setSortColumnId] = useState<ColumnId>(
    ColumnId.STARTED_AT
  )
  const valueSelector = useMemo<(l: AnalyticErrorT) => string>(
    () => (l) => {
      switch (sortColumnId) {
        case ColumnId.ERROR:
          return formatError(l.error)
        case ColumnId.STARTED_AT:
          return DateTime.fromISO(l.startedAt).valueOf().toString()
        case ColumnId.STOPPED_AT:
          return DateTime.fromISO(l.stoppedAt).valueOf().toString()
        case ColumnId.ACK_AT:
          return DateTime.fromISO(l.ackAt).valueOf().toString()
        case ColumnId.ACK_BY:
          return formatUserId(l.ackBy ?? '')
      }
    },
    [formatError, formatUserId, sortColumnId]
  )
  const sortedData = useMemo(
    () =>
      errors.slice().sort((l, r) => {
        const [lValue, rValue] = objectSelector(l, r).map(valueSelector)
        return lValue.localeCompare(rValue)
      }),
    [errors, objectSelector, valueSelector]
  )
  const handleSort = useCallback(
    (columnId: string) => {
      const cid = ColumnId[columnId as keyof typeof ColumnId]
      if (cid === sortColumnId) {
        setSortAsc((prev) => !prev)
      } else {
        setSortColumnId(cid)
        setSortAsc(true)
      }
    },
    [sortColumnId]
  )

  return (
    <TableBuilder
      data={sortedData}
      sortColumn={sortColumnId}
      sortOrder={sortAsc ? 'ASC' : 'DESC'}
      onSort={handleSort}
      size={SIZE.compact}
      emptyMessage
    >
      <TableBuilderColumn
        id={ColumnId.ERROR}
        header={t('dashboard.analyticerrors.error')}
        sortable
      >
        {(row: AnalyticErrorT) => formatError(row.error)}
      </TableBuilderColumn>
      <TableBuilderColumn
        id={ColumnId.STARTED_AT}
        header={t('dashboard.analyticerrors.startedAt')}
        sortable
      >
        {(row: AnalyticErrorT) => formatDateTime(row.startedAt)}
      </TableBuilderColumn>
      <TableBuilderColumn
        id={ColumnId.STOPPED_AT}
        header={t('dashboard.analyticerrors.stoppedAt')}
        sortable
      >
        {(row: AnalyticErrorT) => formatDateTime(row.stoppedAt)}
      </TableBuilderColumn>
      {!setAck && (
        <TableBuilderColumn
          id={ColumnId.ACK_AT}
          header={t('dashboard.analyticerrors.ackAt')}
          sortable
        >
          {(row: AnalyticErrorT) => formatDateTime(row.ackAt)}
        </TableBuilderColumn>
      )}
      {!setAck && (
        <TableBuilderColumn
          id={ColumnId.ACK_BY}
          header={t('dashboard.analyticerrors.ackBy')}
          sortable
        >
          {(row: AnalyticErrorT) => formatUserId(row.ackBy)}
        </TableBuilderColumn>
      )}
      {setAck && (
        <TableBuilderColumn
          header={t('dashboard.analyticerrors.actions')}
          numeric
        >
          {(row: AnalyticErrorT) => (
            <Button onClick={() => setAck([row])} $size={SIZE.compact}>
              {t('dashboard.analyticerrors.ack')}
            </Button>
          )}
        </TableBuilderColumn>
      )}
    </TableBuilder>
  )
})
