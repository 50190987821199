import { useStyletron } from 'baseui'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { Tag, VARIANT } from 'baseui/tag'
import { DateTime, Interval, Zone } from 'luxon'
import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DateChooser } from './date-chooser'

export enum TimeRange {
  LAST_DAY = 1,
  LAST_WEEK,
  LAST_MONTH,
  LAST_YEAR,
}

export type TimeRangeT = TimeRange | Interval

type TimerangeSelectorProps = Readonly<{
  availableTimeRanges: ReadonlyArray<TimeRange | 'CUSTOM'>
  selectedTimeRange: TimeRangeT
  onChange: (timeRange: TimeRangeT) => any
}>
export const TimerangeSelector: FC<TimerangeSelectorProps> = memo((props) => {
  const { selectedTimeRange, availableTimeRanges, onChange } = props

  const [css] = useStyletron()
  return (
    <div
      className={css({
        flex: 'auto',
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      {availableTimeRanges.map((timeRange) => {
        if (timeRange === 'CUSTOM')
          return (
            <CustomTimerangeTag
              key={timeRange}
              timeRange={
                Interval.isInterval(selectedTimeRange)
                  ? selectedTimeRange
                  : undefined
              }
              selected={Interval.isInterval(selectedTimeRange)}
              onSelect={onChange}
            />
          )
        else
          return (
            <TimerangeTag
              key={timeRange}
              timeRange={timeRange}
              selected={timeRange === selectedTimeRange}
              onSelect={onChange}
            />
          )
      })}
    </div>
  )
})

type TimerangeTagProps = Readonly<{
  timeRange: TimeRange
  selected: boolean
  onSelect: (timeRange: TimeRange) => any
}>
const TimerangeTag: FC<TimerangeTagProps> = memo((props) => {
  const { timeRange, selected, onSelect } = props
  const label = useMemo(() => {
    switch (timeRange) {
      case TimeRange.LAST_DAY:
        return 'timerange.last24Hours'
      case TimeRange.LAST_WEEK:
        return 'timerange.lastWeek'
      case TimeRange.LAST_MONTH:
        return 'timerange.lastMonth'
      case TimeRange.LAST_YEAR:
        return 'timerange.lastYear'
    }
  }, [timeRange])

  const { t } = useTranslation()
  return (
    <Tag
      kind='red'
      variant={selected ? VARIANT.solid : VARIANT.outlined}
      closeable={false}
      onClick={() => onSelect(timeRange)}
    >
      {t(label)}
    </Tag>
  )
})
type CustomTimerangeTagProps = Readonly<{
  timeRange: Interval | undefined
  selected: boolean
  onSelect: (interval: Interval) => any
}>
const CustomTimerangeTag: FC<CustomTimerangeTagProps> = memo((props) => {
  const { timeRange, selected, onSelect } = props
  const interval = useMemo(
    () =>
      timeRange?.mapEndpoints((dt) =>
        dt.setZone('local', { keepLocalTime: true })
      ),
    [timeRange]
  )

  const label = useMemo(() => {
    if (selected && timeRange?.start && timeRange?.end) {
      const start = timeRange.start.toLocaleString()
      const end = timeRange.end.toLocaleString()
      return `${start} - ${end}`
    }
  }, [selected, timeRange])

  const { t } = useTranslation()
  return (
    <StatefulPopover
      content={({ close }) => (
        <DateChooser
          value={
            interval?.start && interval?.end
              ? [interval.start.toJSDate(), interval.end.toJSDate()]
              : []
          }
          close={close}
          onSelect={([start, end]: [Date, Date]) =>
            onSelect(Interval.fromDateTimes(start, end))
          }
        />
      )}
      placement={PLACEMENT.rightTop}
      returnFocus
      autoFocus
      showArrow
    >
      <Tag
        kind='red'
        variant={selected ? VARIANT.solid : VARIANT.outlined}
        closeable={false}
        overrides={{
          Text: {
            style: {
              maxWidth: '256px',
            },
          },
        }}
      >
        {label ?? t('timerange.custom')}
      </Tag>
    </StatefulPopover>
  )
})

export function useDataInterval(
  timeRange: TimeRangeT,
  timezone?: string | Zone | undefined
) {
  return useMemo(() => {
    if (Interval.isInterval(timeRange)) {
      return timeRange.mapEndpoints((dt) =>
        dt.setZone(timezone, { keepLocalTime: true })
      )
    }

    const now = DateTime.now().setZone(timezone)
    let start = DateTime.invalid('empty')
    let end = DateTime.invalid('empty')
    if (timeRange === TimeRange.LAST_DAY) {
      end = now.startOf('hour')
      start = end.minus({ days: 1 })
    } else if (timeRange === TimeRange.LAST_WEEK) {
      start = now.minus({ weeks: 1 }).startOf('week')
      end = start.endOf('week')
    } else if (timeRange === TimeRange.LAST_MONTH) {
      start = now.minus({ months: 1 }).startOf('month')
      end = start.endOf('month')
    } else if (timeRange === TimeRange.LAST_YEAR) {
      start = now.minus({ years: 1 }).startOf('year')
      end = start.endOf('year')
    }
    return Interval.fromDateTimes(start, end)
  }, [timeRange, timezone])
}
