import { useStyletron } from 'baseui'
import { AnalyticErrorKey, AnalyticErrorStatus } from 'client'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../../ApiProvider'
import { MachineT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'
import { StateCard } from './state-card'

type StateProps = Readonly<{
  machine: MachineT | undefined
}>

export const State: React.FC<StateProps> = memo(({ machine }) => {
  const [css] = useStyletron()
  const { t } = useTranslation()
  const { analyticErrApi } = useApi()
  const [analyticErrors, setAnalyticErrors] = useState<AnalyticErrorStatus[]>(
    []
  )
  const eventStates = useMemo(
    () =>
      KEYS.map((key): EventStatusT => {
        const analyticError = analyticErrors.find(({ name }) => name === key)
        const translations: {
          active: string
          inactive: string
          description: string
          details: [string]
        } = t(`analyticalStatus.${key}`, { returnObjects: true })

        const isActive = analyticError?.is_active
        return {
          name: key,
          displayName:
            (isActive ? translations.active : translations.inactive) ??
            `[${key}]`,
          description: translations.description ?? key,
          details: translations.details ?? [],
          is_active: isActive,
        }
      }).map((state) =>
        state.name === AnalyticErrorKey.AVAILABILITY
          ? {
              ...state,
              is_active:
                state.is_active !== undefined ? !state.is_active : undefined,
            }
          : state
      ),
    [analyticErrors, t]
  )
  const { startLoading, stopLoading } = useLoadIndicator()
  const loadAnalyticErrors = useCallback(() => {
    if (machine?.id) {
      startLoading()
      analyticErrApi
        .getAnalyticErrorStates(machine.id, [...KEYS])
        .then((response) => response.data)
        .then(setAnalyticErrors)
        .catch(() => setAnalyticErrors([]))
        .finally(stopLoading)
    } else {
      setAnalyticErrors([])
    }
  }, [analyticErrApi, machine?.id, startLoading, stopLoading])
  useEffect(
    function periodicLoadAnalyticErrors() {
      loadAnalyticErrors()
      const interval = setInterval(loadAnalyticErrors, 10000)
      return () => clearInterval(interval)
    },
    [loadAnalyticErrors]
  )

  return (
    <div
      style={{
        flexGrow: 1,
        height: 0,
        overflowY: 'auto',
      }}
    >
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
          gap: '1em',
        })}
      >
        {eventStates.map((data) => {
          return (
            <StateCard
              key={`${data.name}`}
              name={data.displayName}
              description={data.description}
              details={data.details}
              statusOK={!data.is_active}
            />
          )
        })}
      </div>
    </div>
  )
})

const KEYS = [
  AnalyticErrorKey.AVAILABILITY,
  AnalyticErrorKey.STOPPED,
  AnalyticErrorKey.OFFLINE_CLEANING,
  AnalyticErrorKey.EVAPORATION_STARTPROCESS_ERROR,
  AnalyticErrorKey.DROPLETSEPARATOR_DIFFERENTIAL_PRESSURE_HIGH,
  AnalyticErrorKey.FINAL_CONCENTRATOR_BLOCKED,
  AnalyticErrorKey.FINAL_CONCENTRATOR_NOT_ENOUGH_STEAM,
  AnalyticErrorKey.FINAL_CONCENTRATOR_DISCHARGE_BLOCKED,
  AnalyticErrorKey.CONCENTRATE_TANK_DISCHARGE_BLOCKED,
  AnalyticErrorKey.AMOUNT_OF_WATER_HIGH,
  AnalyticErrorKey.AMOUNT_OF_WATER_LOW,
  AnalyticErrorKey.AUTOMATIC_FILTER_DIRTY,
  AnalyticErrorKey.CLEAR_PHASE_PRODUCTION_LOW,
  AnalyticErrorKey.FEED_WASTE_WATER_LOW,
  AnalyticErrorKey.CONDENSATE_PRODUCTION_LOW,
  AnalyticErrorKey.TEMPERATURE_DIFFERENCE_HIGH,
  AnalyticErrorKey.PRESSURE_DIFFERENCE_HIGH,
  AnalyticErrorKey.THROUGHPUT_ENDCONCENTRATOR_LOW,
  AnalyticErrorKey.CONCENTRATE_DISPOSAL_LOW,
  AnalyticErrorKey.PRECONCENTRATOR_NOT_REFILLED,
  AnalyticErrorKey.ONE_STEP_TAKES_TOO_LONG,
  AnalyticErrorKey.OFFLINE_CLEANING_STEP_TAKES_TOO_LONG,
  AnalyticErrorKey.PSG1_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT,
  AnalyticErrorKey.PSG2_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT,
] as const

type EventStatusT = Omit<AnalyticErrorStatus, 'is_active'> & {
  displayName: string
  description: string
  details: string[]
  is_active?: boolean
}
