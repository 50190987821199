import { useStyletron } from 'baseui'
import { ProgressBar } from 'baseui/progress-bar'
import React, {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type LoadIndicatorT = {
  isLoading: boolean
  startLoading: () => void
  stopLoading: () => void
}

const LoadIndicatorContext = createContext<LoadIndicatorT>({
  isLoading: false,
  startLoading: () => {},
  stopLoading: () => {},
})

export const LoadIndicatorProvider: FC = memo(({ children }) => {
  const [loading, setLoading] = useState(0)
  const start = useCallback(() => setLoading((prev) => prev + 1), [])
  const stop = useCallback(
    () => setLoading((prev) => Math.max(0, prev - 1)),
    []
  )
  const loadIndicatorStatus = useMemo(
    () => ({
      isLoading: loading > 0,
      startLoading: start,
      stopLoading: stop,
    }),
    [loading, start, stop]
  )

  return (
    <LoadIndicatorContext.Provider value={loadIndicatorStatus}>
      {children}
    </LoadIndicatorContext.Provider>
  )
})

export const useLoadIndicator = (): LoadIndicatorT => {
  return useContext(LoadIndicatorContext)
}

export const LoadIndicator: FC = memo((props) => {
  const [css] = useStyletron()
  const { isLoading } = useLoadIndicator()
  return (
    <div
      className={css({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 999,
      })}
    >
      <ProgressBar
        infinite
        overrides={{
          BarContainer: {
            style: {
              visibility: isLoading ? 'unset' : 'hidden',
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              marginTop: 0,
            },
          },
          InfiniteBar: {
            style: {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            },
          },
        }}
      />
    </div>
  )
})
