import { mdiDotsGrid, mdiMenu } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { Button, KIND } from 'baseui/button'
import { HeadingMedium } from 'baseui/typography'
import { Aggregate, AggregationMode, MeasurementsMultiAggregate } from 'client'
import { DateTime, DateTimeFormatOptions, Interval } from 'luxon'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../../ApiProvider'
import { LocationT, MachineT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'
import { Chart } from './chart'
import {
  TimeRange,
  TimerangeSelector,
  useDataInterval,
} from './timerange-selector'

type HeatExchangerDashboardProps = Readonly<{
  location: LocationT | undefined
  machine: MachineT | undefined
}>

export const HeatExchangerDashboard: React.FC<HeatExchangerDashboardProps> =
  memo(({ location, machine }) => {
    const { sensorApi } = useApi()
    const [timeRange, setTimeRange] = useState<TimeRange | Interval>(
      TimeRange.LAST_DAY
    )
    const dataInterval = useDataInterval(timeRange, location?.timezone)

    const aggregationMode = useMemo(() => {
      if (Interval.isInterval(timeRange)) {
        return AggregationMode.DAYS
      }
      switch (timeRange) {
        case TimeRange.LAST_DAY:
          return AggregationMode.HOURS
        case TimeRange.LAST_WEEK:
        case TimeRange.LAST_MONTH:
          return AggregationMode.DAYS
        case TimeRange.LAST_YEAR:
          return AggregationMode.MONTHS
        default:
          return AggregationMode.HOURS
      }
    }, [timeRange])
    const [rawAggregates, setRawAggregates] = useState<
      ReadonlyArray<MeasurementsMultiAggregate>
    >([])
    const { isLoading, startLoading, stopLoading } = useLoadIndicator()
    useEffect(
      function loadRawAggregates() {
        const start = dataInterval.start?.toISO()
        const end = dataInterval.end?.toISO()
        if (machine?.id && start && end) {
          startLoading()
          sensorApi
            .getSensorMultiAggregates(
              machine.id,
              start,
              end,
              aggregationMode,
              CHART_SETTINGS.map(({ key }) => key)
            )
            .then((response) => response.data)
            .then(setRawAggregates)
            .finally(stopLoading)

          return () => setRawAggregates([])
        }
      },
      [
        aggregationMode,
        dataInterval.end,
        dataInterval.start,
        machine?.id,
        sensorApi,
        startLoading,
        stopLoading,
      ]
    )

    const timestampFormat: DateTimeFormatOptions = useMemo(() => {
      switch (aggregationMode) {
        case 'HOURS':
          return DateTime.TIME_SIMPLE
        case 'DAYS':
          return { month: 'long', day: 'numeric' }
        case 'MONTHS':
          return { month: 'long', year: 'numeric' }
        default:
          return DateTime.DATE_FULL
      }
    }, [aggregationMode])

    const aggregates = useMemo(
      () =>
        [...rawAggregates]
          .sort((l, r) => l.timestamp.localeCompare(r.timestamp))
          .map((agg) => ({
            ...agg,
            values: Object.keys(agg.values).reduce((result, key) => {
              return {
                ...result,
                [key]: getAggregateValue(key, aggregationMode, agg.values[key]),
              }
            }, {}),
            timestamp: DateTime.fromISO(agg.timestamp).toLocaleString(
              timestampFormat
            ),
          })),
      [rawAggregates, timestampFormat, aggregationMode]
    )
    const [gridViewEnabled, setGridViewEnabled] = useState(true)

    const [css] = useStyletron()
    const { t } = useTranslation()
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '50px',
            flexDirection: 'row',
          }}
        >
          <div
            className={css({
              flex: 'auto',
              display: 'flex',
              flexDirection: 'row',
            })}
          >
            <TimerangeSelector
              availableTimeRanges={[
                TimeRange.LAST_DAY,
                TimeRange.LAST_WEEK,
                TimeRange.LAST_MONTH,
                'CUSTOM',
              ]}
              selectedTimeRange={timeRange}
              onChange={setTimeRange}
            />
          </div>

          <Button
            onClick={() => setGridViewEnabled(!gridViewEnabled)}
            kind={KIND.tertiary}
          >
            {gridViewEnabled ? (
              <Icon path={mdiMenu} size={1} />
            ) : (
              <Icon path={mdiDotsGrid} size={1} />
            )}
          </Button>
        </div>

        {aggregates.length !== 0 ? (
          <div
            className={css({
              flexGrow: 1,
              flexShrink: 0,
              height: 0,
              overflow: 'auto',
            })}
          >
            <div
              className={css({
                ...(gridViewEnabled
                  ? {
                      gridTemplateColumns:
                        'repeat(auto-fill, minmax(400px, 1fr))',
                      gridAutoRows: '200px',
                    }
                  : {
                      gridAutoRows: '200px',
                    }),
                display: 'grid',
                paddingTop: '0.5em',
                paddingLeft: '0.5em',
                paddingBottom: '0.5em',
                paddingRight: '0.5em',
                gap: '1em',
                overflow: 'hidden',
              })}
            >
              {CHART_SETTINGS.map((setting) => (
                <Chart
                  key={setting.key}
                  header={t(setting.caption)}
                  isGridViewEnabled={gridViewEnabled}
                  data={aggregates}
                  dataKey={`values.${setting.key}`}
                  barColor={setting.color}
                  valueFormatter={(value) =>
                    Number.parseFloat(value.toString()).toLocaleString(
                      undefined,
                      setting.valueFormatOptions
                    )
                  }
                  nameFormatter={(name) => t(setting.caption).toString()}
                />
              ))}
            </div>
          </div>
        ) : (
          <div
            className={css({
              flex: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })}
          >
            {isLoading && (
              <HeadingMedium>{t('dashboard.loading')}</HeadingMedium>
            )}
            {!isLoading && aggregates.length === 0 && (
              <HeadingMedium>{t('dashboard.noData')}</HeadingMedium>
            )}
          </div>
        )}
      </div>
    )
  })

const HEAT_EXCHANGER_TAGS = [
  'EVA02HE201',
  'EVA04HE202',
  'PSG03HE402',
  'PSG06HE502',
] as const
type HeatExchangerTag = (typeof HEAT_EXCHANGER_TAGS)[number]
type ChartSettingT = {
  key: HeatExchangerTag
  displayName?: string
  caption: string
  color: string
  valueFormatOptions?: Intl.NumberFormatOptions
}
const CHART_SETTINGS: ReadonlyArray<ChartSettingT> = [
  {
    key: 'EVA02HE201',
    // displayName: 'Feed Evaporation',
    caption: 'heatexchanger.nameChart1',
    color: 'rgba(0,121,184,1)',
  },
  {
    key: 'EVA04HE202',
    // displayName: 'Feed Evaporation',
    caption: 'heatexchanger.nameChart2',
    color: '#03703C',
  },
  {
    key: 'PSG03HE402',
    caption: 'heatexchanger.nameChart3',
    color: '#FFC043',
  },
  {
    key: 'PSG06HE502',
    caption: 'heatexchanger.nameChart4',
    color: '#3BA3DD',
  },
] as const

const getAggregateValue = (
  tag: string,
  aggregationMode: AggregationMode,
  aggregate: Aggregate
): number => {
  switch (tag) {
    case 'EVA02HE201':
    case 'EVA04HE202':
    case 'PSG03HE402':
    case 'PSG06HE502':
    default:
      return aggregate.avg
  }
}
