import { mdiAccountMultiple, mdiOfficeBuilding } from '@mdi/js'
import axios from 'axios'
import { useStyletron } from 'baseui'
import { Icon } from 'baseui/icon'
import { ListItemLabel, MenuAdapter } from 'baseui/list'
import { StatefulMenu } from 'baseui/menu'
import { LabelLarge, LabelSmall } from 'baseui/typography'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import SchraderBackground from '../../assets/schrader-background.jpeg'

export const Settings: React.FC = React.memo(() => {
  const [buildInfo, setBuildInfo] = useState<any>()
  useEffect(() => {
    axios
      .get(`${window.env.APP_BASE_PATH}/actuator/info`)
      .then((result) => result.data)
      .then(setBuildInfo)
  }, [])

  const [css, theme] = useStyletron()
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div
      className={css({
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.sizing.scale400,
        paddingLeft: theme.sizing.scale800,
        paddingBottom: theme.sizing.scale600,
        paddingRight: theme.sizing.scale800,
        backgroundImage: `url(${SchraderBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      })}
    >
      <div
        className={css({
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '38px',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          backgroundColor: 'rgba(239,244,249,1)',
          marginTop: '1em',
          marginBottom: '2em',
        })}
      >
        <LabelLarge
          className={css({
            display: 'flex',
            fontSize: '22px',
            fontWeight: 'bolder',
            color: 'rgba(0,121,184,1)',
            alignSelf: 'center',
          })}
        >
          {t('navigation.settings')}
        </LabelLarge>{' '}
      </div>

      <StatefulMenu
        items={[
          {
            label: 'Manage Tenants',
            to: '/settings/tenants',
            icon: () => <Icon path={mdiOfficeBuilding} size={'24px'} />,
          },
          {
            label: 'Manage Accounts',
            to: '/settings/users',
            icon: () => <Icon path={mdiAccountMultiple} size={'24px'} />,
          },
        ]}
        onItemSelect={({ item }) => {
          navigate(item.to)
        }}
        overrides={{
          List: {
            style: {
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
            },
          },
          Option: {
            props: {
              overrides: {
                ListItem: {
                  component: React.forwardRef((props: any, ref) => (
                    <MenuAdapter
                      {...props}
                      ref={ref}
                      overrides={{
                        Content: {
                          style: ({ $theme }) => ({
                            minHeight: $theme.sizing.scale1200,
                          }),
                        },
                      }}
                    >
                      <ListItemLabel>{props.item.label}</ListItemLabel>
                    </MenuAdapter>
                  )),
                },
              },
            },
          },
        }}
      />

      <div className={css({ marginTop: '1em' })}>
        <LabelSmall>Version: {buildInfo?.build?.version}</LabelSmall>
        <LabelSmall>(Commit-Id: {buildInfo?.git?.commit.id})</LabelSmall>
      </div>
    </div>
  )
})
