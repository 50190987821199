import { useStyletron } from 'baseui'
import { PLACEMENT, ToasterContainer } from 'baseui/toast'
import { Location } from 'client'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext } from 'react-router-dom'

import { useApi } from '../../ApiProvider'
import { MachineT } from '../backend-data'
import { LoadIndicator, useLoadIndicator } from '../load-indicator'
import { SideNavigation } from './side-navigation'

type OutletContextT = Readonly<{
  selectedMachine: MachineT | undefined
  availableMachines: Array<MachineT>
  setSelectedMachine: (machine: MachineT) => void
}>
export const useSelectedMachine = () => {
  const { selectedMachine } = useOutletContext<OutletContextT>()
  return selectedMachine
}
export const useAvailableMachines = () => {
  const { availableMachines } = useOutletContext<OutletContextT>()
  return availableMachines
}
export const useSetSelectedMachine = () => {
  const { setSelectedMachine } = useOutletContext<OutletContextT>()
  return setSelectedMachine
}

export const Page: React.FC<{}> = memo(() => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState('English')

  const { locationApi, machineApi } = useApi()
  const [availableLocations, setAvailableLocations] = useState<
    ReadonlyArray<Location>
  >([])
  const [availableMachines, setAvailableMachines] = useState<
    ReadonlyArray<MachineT>
  >([])
  const [selectedMachine, setSelectedMachine] = useState<MachineT>()
  const { startLoading, stopLoading } = useLoadIndicator()

  useEffect(() => {
    switch (language) {
      case 'Deutsch':
        i18n.changeLanguage('de')
        break
      case 'English':
        i18n.changeLanguage('en')
        break
      case 'Portuguese':
        i18n.changeLanguage('pt')
        break

      default:
        i18n.changeLanguage('en')
        break
    }
  }, [i18n, language])

  // Load locations
  useEffect(() => {
    startLoading()
    locationApi
      .getLocations()
      .then((response) => {
        setAvailableLocations(response.data)
      })
      .finally(stopLoading)
  }, [locationApi, startLoading, stopLoading])

  // Load machines
  useEffect(() => {
    if (availableLocations.length > 0) {
      startLoading()
      Promise.all(
        availableLocations.map((location) =>
          machineApi
            .getMachinesByLocation(location.id!)
            .then((response) => response.data)
            .then((machines) =>
              machines.map((machine): MachineT => ({ ...machine, location }))
            )
        )
      )
        .then((responses) => responses.flatMap((machines) => machines))
        .then(setAvailableMachines)
        .finally(stopLoading)
    }
  }, [availableLocations, machineApi, startLoading, stopLoading])

  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        width: '100%',
        height: '100vh',
        flexDirection: 'row',
        overflow: 'hidden',
      })}
    >
      <SideNavigation
        availableMachines={availableMachines}
        selectedMachine={selectedMachine}
        onChangeMachine={setSelectedMachine}
        availableLocations={availableLocations}
        language={language}
        onChangeLanguage={setLanguage}
      />

      <div
        className={css({
          flex: 'auto',
          display: 'flex',
          flexDirection: 'column',
          ...theme.typography.ParagraphMedium,
        })}
      >
        <LoadIndicator />
        <Outlet
          context={{
            selectedMachine: selectedMachine,
            availableMachines: availableMachines,
            setSelectedMachine: setSelectedMachine,
          }}
        />
      </div>

      <ToasterContainer
        placement={PLACEMENT.top}
        autoHideDuration={2000}
        overrides={{
          ToastBody: {
            style: {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            },
          },
        }}
      />
    </div>
  )
})
