import { mdiCircle, mdiHelpBox } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { StatefulPopover } from 'baseui/popover'
import { SIZE, TableBuilder, TableBuilderColumn } from 'baseui/table-semantic'
import { AggregationMode, AnalyticErrorKey, MeasurementStatus } from 'client'
import { Property } from 'csstype'
import { DateTime } from 'luxon'
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { AutoSizer } from 'react-virtualized'
import { Line, LineChart, YAxis } from 'recharts'

import { ApiT, useApi } from '../../ApiProvider'
import { MachineT } from '../backend-data'
import { useLoadIndicator } from '../load-indicator'

const STATUS_SIGNALS = [
  'EVA01F201', // Abwasserdurchfluss
  'EVA03S501', // Drehzahl Brüdenverdichter
  'LeistungMVR', // Leistungsaufnahme Brüdenverdichter
  'EVA04F209', // Durchfluss Endkonzentrator
  'PSG03P416', //Druck PSG1
  'PSG06P516', //Druck PSG2
] as const
const CHART_SIGNALS = [
  'EVA01F201', // Feed Abwasser
  'EVA03S501', // Drehzahl Brüdenverdichter
  'PRE02L103', // Füllstand Abwassertank
  'TAN02L302', // Füllstand Kondensattank
] as const

type LocationsOverviewProps = Readonly<{
  machines: ReadonlyArray<MachineT>
  setSelectedMachine: (machine: MachineT) => void
}>

export const LocationsOverview: React.FC<LocationsOverviewProps> = memo(
  (props) => {
    const { machines, setSelectedMachine } = props
    const apis = useApi()
    const { startLoading, stopLoading } = useLoadIndicator()

    const [data, setData] = useState<MachineDataRecord[]>([])
    const timestampDomain = useMemo<[number, number]>(() => {
      const timestamps = data
        .flatMap((d) => d.chartData)
        .map(({ timestamp }) => timestamp)
      return [Math.min(...timestamps), Math.max(...timestamps)]
    }, [data])
    const fetchData = useCallback(() => {
      startLoading()
      Promise.all(machines.map((machine) => fetchMachineData(machine, apis)))
        .then(setData)
        .finally(() => stopLoading())
    }, [apis, machines, startLoading, stopLoading])
    useEffect(
      function periodicFetchData() {
        fetchData()
        const interval = setInterval(fetchData, 600000)
        return () => clearInterval(interval)
      },
      [fetchData]
    )

    const [t] = useTranslation()
    const [css] = useStyletron()
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TableBuilder
          data={data}
          size={SIZE.compact}
          overrides={{
            TableHeadCell: {
              style: {
                textAlign: 'center',
                verticalAlign: 'middle',
                whiteSpace: 'wrap',
              },
            },
            TableBodyCell: {
              style: {
                textAlign: 'center',
                verticalAlign: 'middle',
                whiteSpace: 'wrap',
              },
            },
          }}
        >
          <TableBuilderColumn header={t('locationsOverview.status')}>
            {(row: MachineDataRecord) => (
              <MachineStatusCell status={row.machineStatus} />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn header={t('locationsOverview.name')}>
            {(row: MachineDataRecord) => (
              <div
                onClick={() => setSelectedMachine(row.machine)}
                className={css({ cursor: 'pointer' })}
              >
                {`${row.machine.location?.name} - ${row.machine.name}`}
              </div>
            )}
          </TableBuilderColumn>

          {STATUS_SIGNALS.map((signal) => (
            <TableBuilderColumn
              key={signal}
              header={t(STATUS_SETTINGS[signal].header)}
            >
              {(row: MachineDataRecord) => {
                const value = row.statusData?.[signal] ?? 0
                const { unit, valueFormatOptions } = STATUS_SETTINGS[signal]
                const formattedValue = Number(value).toLocaleString(
                  undefined,
                  valueFormatOptions
                )
                return `${formattedValue} ${unit}`
              }}
            </TableBuilderColumn>
          ))}

          <TableBuilderColumn
            header={<ChartColumnHeader timestampDomain={timestampDomain} />}
          >
            {({ chartData }: MachineDataRecord) => (
              <div
                className={css({
                  minWidth: '20em',
                  height: '5em',
                })}
              >
                <Chart records={chartData} />
              </div>
            )}
          </TableBuilderColumn>
        </TableBuilder>
      </div>
    )
  }
)

interface MachineStatusCellProps
  extends Readonly<{
    status: MachineStatus
  }> {}

const MachineStatusCell: React.FC<MachineStatusCellProps> = memo((props) => {
  const { status } = props
  const [t] = useTranslation()
  const [, theme] = useStyletron()
  const getMachineStatusColor = useCallback(
    (machineStatus: MachineStatus): Property.Color => {
      // rot   - es liegen aktive Fehler an
      // gelb  - es liegen offene Fehler an, welche noch nicht quittiert wurden
      // grün  - es liegen keine offenen Fehler an
      // grau   - Anlage ist aus / offline
      switch (machineStatus) {
        case 'active_errors':
          return theme.colors.negative
        case 'open_errors':
          return theme.colors.warning
        case 'no_errors':
          return theme.colors.positive
        case 'offline':
        default:
          return 'grey'
      }
    },
    [theme.colors.negative, theme.colors.positive, theme.colors.warning]
  )
  const getMachineStatusLabel = useCallback(
    (machineStatus: MachineStatus): string => {
      switch (machineStatus) {
        case 'active_errors':
          return t('locationsOverview.machineStatus.active_errors')
        case 'open_errors':
          return t('locationsOverview.machineStatus.open_errors')

        case 'no_errors':
          return t('locationsOverview.machineStatus.no_errors')
        case 'offline':
        default:
          return t('locationsOverview.machineStatus.offline')
      }
    },
    [t]
  )

  return (
    <StatefulPopover
      content={
        <MachineStatusLegend
          getMachineStatusColor={getMachineStatusColor}
          getMachineStatusLabel={getMachineStatusLabel}
        />
      }
      triggerType={'hover'}
      placement={'bottomRight'}
    >
      <Icon
        path={mdiCircle}
        size={'2em'}
        color={getMachineStatusColor(status)}
      />
    </StatefulPopover>
  )
})

interface MachineStatusLegendProps
  extends Readonly<{
    getMachineStatusColor: (machineStatus: MachineStatus) => Property.Color
    getMachineStatusLabel: (machineStatus: MachineStatus) => string
  }> {}

const MachineStatusLegend: React.FC<MachineStatusLegendProps> = memo(
  (props) => {
    const { getMachineStatusColor, getMachineStatusLabel } = props

    return (
      <TableBuilder
        data={[...MACHINE_STATES]}
        size={SIZE.compact}
        overrides={{
          TableBodyCell: {
            style: {
              textAlign: 'center',
              verticalAlign: 'middle',
              whiteSpace: 'wrap',
            },
          },
        }}
      >
        <TableBuilderColumn header={null}>
          {(machineStatus: MachineStatus) => (
            <Icon
              path={mdiCircle}
              size={'1em'}
              color={getMachineStatusColor(machineStatus)}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header={null}
          overrides={{ TableBodyCell: { style: { textAlign: 'left' } } }}
        >
          {(machineStatus: MachineStatus) =>
            getMachineStatusLabel(machineStatus)
          }
        </TableBuilderColumn>
      </TableBuilder>
    )
  }
)

const ChartColumnHeader: React.FC<{ timestampDomain: [number, number] }> = memo(
  (props) => {
    const { timestampDomain } = props
    const [t] = useTranslation()
    const [css] = useStyletron()
    return (
      <div
        className={css({
          display: 'inline-flex',
          gap: '0.5em',
        })}
      >
        {t('locationsOverview.chart')}
        <StatefulPopover
          content={<Legend timestampDomain={timestampDomain} />}
          placement={'left'}
        >
          <Icon path={mdiHelpBox} size={'1.5em'} />
        </StatefulPopover>
      </div>
    )
  }
)
const Legend: React.FC<{ timestampDomain: [number, number] }> = memo(
  (props) => {
    const {
      timestampDomain: [minTimestamp, maxTimestamp],
    } = props
    const [t] = useTranslation()

    const { i18n } = useTranslation()
    const formatDateTime = useCallback(
      (millis: number) => {
        return DateTime.fromMillis(millis).toLocaleString(
          {
            dateStyle: 'short',
            timeStyle: 'short',
          },
          { locale: i18n.language }
        )
      },
      [i18n.language]
    )
    const [css] = useStyletron()
    return (
      <>
        <div className={css({ padding: '1em' })}>
          {formatDateTime(minTimestamp)} - {formatDateTime(maxTimestamp)}
        </div>
        <TableBuilder
          data={[...CHART_SIGNALS]}
          size={SIZE.compact}
          overrides={{
            TableBodyCell: {
              style: {
                textAlign: 'center',
                verticalAlign: 'middle',
                whiteSpace: 'wrap',
              },
            },
          }}
        >
          <TableBuilderColumn header={null}>
            {(signal: ChartSignal) => (
              <Icon
                path={mdiCircle}
                size={'1em'}
                color={CHART_SETTINGS[signal].color}
              />
            )}
          </TableBuilderColumn>
          <TableBuilderColumn
            header={null}
            overrides={{ TableBodyCell: { style: { textAlign: 'left' } } }}
          >
            {(signal: ChartSignal) => t(CHART_SETTINGS[signal].label)}
          </TableBuilderColumn>
        </TableBuilder>
      </>
    )
  }
)
type ChartProps = Readonly<{
  records: ReadonlyArray<ChartDataRecord>
}>
const Chart: React.FC<ChartProps> = memo((props) => {
  const { records } = props
  const signals = CHART_SIGNALS

  const data: ChartDataRecord[] = useMemo(() => [...records], [records])

  return (
    <AutoSizer>
      {({ width, height }) => (
        <LineChart width={width} height={height} data={data}>
          {signals.map((signal) => (
            <Fragment key={signal}>
              <YAxis yAxisId={signal} domain={['auto', 'auto']} hide />
              <Line
                dataKey={signal}
                yAxisId={signal}
                dot={false}
                isAnimationActive={false}
                stroke={CHART_SETTINGS[signal].color}
                strokeWidth={3}
                strokeOpacity={0.5}
              />
            </Fragment>
          ))}
        </LineChart>
      )}
    </AutoSizer>
  )
})

const MACHINE_STATES = [
  'active_errors',
  'open_errors',
  'no_errors',
  'offline',
] as const
type MachineStatus = (typeof MACHINE_STATES)[number]

type StatusSignal = (typeof STATUS_SIGNALS)[number]
type StatusSetting = Readonly<{
  header: string
  unit: string
  valueFormatOptions: Intl.NumberFormatOptions
}>
type StatusSettings = Readonly<{
  [k in StatusSignal]: StatusSetting
}>
const STATUS_SETTINGS: StatusSettings = {
  EVA01F201: {
    header: 'locationsOverview.VCWasteWater',
    unit: 'm³/h',
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  EVA03S501: {
    header: 'locationsOverview.VCSpeed',
    unit: 'rpm',
    valueFormatOptions: { maximumFractionDigits: 0 },
  },
  LeistungMVR: {
    header: 'locationsOverview.VCPowerConsumption',
    unit: 'kW',
    valueFormatOptions: { maximumFractionDigits: 0 },
  },
  EVA04F209: {
    header: 'locationsOverview.PFConcentrator',
    unit: 'm³/h',
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  PSG03P416: {
    header: 'locationsOverview.PGen1',
    unit: 'bar',
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
  PSG06P516: {
    header: 'locationsOverview.PGen2',
    unit: 'bar',
    valueFormatOptions: { maximumFractionDigits: 2 },
  },
} as const
type StatusDataRecord = Readonly<{
  [k in StatusSignal]: number
}>

type ChartSignal = (typeof CHART_SIGNALS)[number]
type ChartSetting = Readonly<{
  color: Property.Color
  label: string
}>
type ChartSettings = Readonly<{
  [k in ChartSignal]: ChartSetting
}>
const CHART_SETTINGS: ChartSettings = {
  EVA01F201: {
    color: 'green',
    label: 'locationsOverview.EVA01F201',
  },
  EVA03S501: {
    color: 'red',
    label: 'locationsOverview.EVA03S501',
  },
  PRE02L103: {
    color: 'brown',
    label: 'locationsOverview.PRE02L103',
  },
  TAN02L302: {
    color: 'blue',
    label: 'locationsOverview.TAN02L302',
  },
}
type ChartDataRecord = Readonly<
  { timestamp: number } & {
    [key in ChartSignal]: number
  }
>

type MachineDataRecord = Readonly<{
  machine: MachineT
  machineStatus: MachineStatus
  statusData: StatusDataRecord | undefined
  chartData: ReadonlyArray<ChartDataRecord>
}>

async function fetchMachineData(
  machine: MachineT,
  apis: ApiT
): Promise<MachineDataRecord> {
  if (!machine.id) {
    return {
      machine: machine,
      machineStatus: 'offline',
      statusData: undefined,
      chartData: [],
    }
  }
  const now = DateTime.utc()
  const start = now.minus({ day: 1 })
  const [machineStatus, statusData, chartData] = await Promise.all([
    fetchMachineStatus(machine.id, apis),
    fetchStatusData(machine.id, apis),
    fetchChartData(machine.id, start, now, apis),
  ])

  return {
    machine,
    machineStatus,
    statusData,
    chartData,
  }
}

async function fetchMachineStatus(
  machineId: Required<MachineT>['id'],
  apis: ApiT
): Promise<MachineStatus> {
  const { analyticErrApi, statusApi } = apis

  const currentEvaporationStep =
    (await statusApi.getStatus(machineId, ['AktuellerSchrittEVA'])).data.find(
      ({ name }) => name === 'AktuellerSchrittEVA'
    )?.value ?? 0
  if (currentEvaporationStep === 0) {
    return 'offline'
  }

  const analyticErrorKeys = analyticErrApi
    .getAnalyticErrorKeys(machineId)
    .then((response) => response.data)
    .then((keys) => keys.filter((k) => k !== AnalyticErrorKey.AVAILABILITY))
  const activeAnalyticErrors = analyticErrApi
    .getAnalyticErrorStates(machineId, await analyticErrorKeys)
    .then((response) => response.data)
    .then((analyticErrosStates) =>
      analyticErrosStates.filter(({ is_active }) => is_active)
    )
  const openAnalyticErrors = analyticErrApi
    .getOpenAnalyticErrors(machineId)
    .then((response) => response.data)

  if ((await activeAnalyticErrors).length > 0) {
    return 'active_errors'
  } else if ((await openAnalyticErrors).length > 0) {
    return 'open_errors'
  } else {
    return 'no_errors'
  }
}

async function fetchStatusData(
  machineId: Required<MachineT>['id'],
  apis: ApiT
): Promise<StatusDataRecord> {
  const { statusApi } = apis
  const records = await statusApi
    .getStatus(machineId, [...STATUS_SIGNALS])
    .then((response) => response.data)

  const ret = records.reduce((prev, curr: MeasurementStatus) => {
    const { name, value } = curr
    return { ...prev, [name]: value }
  }, {})
  return ret as StatusDataRecord
}

async function fetchChartData(
  machineId: Required<MachineT>['id'],
  start: DateTime,
  end: DateTime,
  apis: ApiT
): Promise<ReadonlyArray<ChartDataRecord>> {
  const { sensorApi } = apis

  const records = await sensorApi
    .getSensorMultiAggregates(
      machineId,
      start.toISO() ?? '',
      end.toISO() ?? '',
      AggregationMode.HOURS,
      [...CHART_SIGNALS]
    )
    .then((response) => response.data)

  return records
    .map(
      (record) =>
        CHART_SIGNALS.map((signal) => ({
          [signal]: record.values[signal]?.avg,
        })).reduce(
          (prev, value) => {
            return { ...prev, ...value }
          },
          { timestamp: DateTime.fromISO(record.timestamp).valueOf() }
        ) as ChartDataRecord
    )
    .sort((l, r) => l.timestamp - r.timestamp)
}
