import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { Option, Select } from 'baseui/select'
import { Textarea } from 'baseui/textarea'
import { LabelLarge } from 'baseui/typography'
import { ReportType } from 'client'
import { DateTime, Duration } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useApi } from '../ApiProvider'
import SchraderBackground from '../assets/schrader-background.jpeg'
import { useSelectedMachine } from './layout/page'
import { useLoadIndicator } from './load-indicator'

export const Report: React.FC = React.memo(() => {
  const selectedMachine = useSelectedMachine()
  const { reportApi } = useApi()
  const { startLoading, stopLoading } = useLoadIndicator()

  const [reportType] = useState<ReportType>(ReportType.MONTH)
  const [selectedDateTime, setSelectedDateTime] = useState<DateTime>()
  const [text, setText] = React.useState('')

  const options = useMemo<Option[]>(() => {
    const units = (() => {
      switch (reportType) {
        case ReportType.WEEK:
          return 'weeks'
        case ReportType.MONTH:
          return 'months'
        case ReportType.YEAR:
          return 'year'
      }
    })()

    const start = DateTime.fromObject({ day: 1, month: 6, year: 2023 })
    const now = DateTime.now()
    const steps = Math.ceil(now.diff(start, units).get(units))
    const dateTimes = Array.from(Array(steps).keys())
      .map((i) => Duration.fromDurationLike({ [units]: i }))
      .map((duration) => start.plus(duration))

    return dateTimes.map((dateTime) => {
      return {
        id: dateTime.toJSDate().toISOString(),
        label: dateTime.toLocaleString({ month: 'long' }),
        __optgroup: `${dateTime.get('year')}`,
      }
    })
  }, [reportType])
  const valueLabel = useMemo(
    () =>
      selectedDateTime?.toLocaleString({
        month: 'long',
        year: 'numeric',
      }),
    [selectedDateTime]
  )

  // get report PDF
  const getReport = React.useCallback((): void => {
    const dateTime = selectedDateTime?.toISO()
    if (selectedMachine?.id && dateTime) {
      startLoading()
      reportApi
        .getReportPDF(selectedMachine?.id, ReportType.MONTH, dateTime, text, {
          responseType: 'arraybuffer',
        })
        .then((response) => new Blob([response.data]))
        .then((blob) => {
          const link = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)

          try {
            link.href = objectUrl
            link.setAttribute(
              'download',
              `Report ${selectedMachine?.name} ${valueLabel}.pdf`
            )
            document.body.appendChild(link)
            link.click()
          } finally {
            URL.revokeObjectURL(objectUrl)
            document.body.removeChild(link)
          }
        })
        .catch((errorMessage) => alert('Error fetching data: ' + errorMessage))
        .finally(stopLoading)
    }
  }, [
    selectedMachine?.id,
    selectedMachine?.name,
    reportApi,
    selectedDateTime,
    startLoading,
    stopLoading,
    text,
    valueLabel,
  ])

  const { t } = useTranslation()
  const [css] = useStyletron()
  return (
    <div
      className={css({
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2em',
        paddingBottom: '2em',
        paddingRight: '4em',
        gap: '2em',
        overflowY: 'auto',
        backgroundImage: `url(${SchraderBackground})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
      })}
    >
      <LabelLarge
        className={css({
          minHeight: '38px',
          fontSize: '22px',
          fontWeight: 'bolder',
          color: 'rgba(0,121,184,1)',
          backgroundColor: 'rgba(239,244,249,1)',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        })}
      >
        {t('report.header')}
      </LabelLarge>

      <div
        className={css({
          flexGrow: 1,
          width: 'calc(100% - 200px)',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        })}
      >
        <Select
          options={options}
          value={options.filter(
            (o) => o.id === selectedDateTime?.toJSDate().toISOString()
          )}
          onChange={({ option }) => {
            setSelectedDateTime(DateTime.fromISO(option?.id?.toString() ?? ''))
          }}
          getValueLabel={() => valueLabel}
          placeholder={'Select month...'}
          clearable={false}
          overrides={{
            ControlContainer: { style: { backgroundColor: 'white' } },
          }}
        />

        <Textarea
          overrides={{
            Root: {
              style: {
                flexGrow: 1,
                minHeight: '185px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                color: 'red',
              },
            },
            InputContainer: {
              style: {
                backgroundColor: 'white',
              },
            },
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder='Text'
          clearOnEscape
        />

        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          })}
        >
          <Button
            disabled={selectedDateTime === undefined}
            overrides={{ Root: { style: { backgroundColor: 'red' } } }}
            onClick={() => getReport()}
          >
            {t('report.createBtn')}
          </Button>
        </div>
      </div>
    </div>
  )
})
