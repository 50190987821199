import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { StyledLink } from 'baseui/link'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useMatches } from 'react-router-dom'

export const TenantDetailsLayout: React.FC = React.memo(() => {
  const [t] = useTranslation()
  const matches = useMatches()

  const crumbs = useMemo(
    () =>
      matches
        .filter((match) => (match.handle as any)?.crumb !== undefined)
        .map((match) => {
          return { crumb: (match.handle as any).crumb, href: match.pathname }
        }),
    [matches]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('navigation.settings')}</span>
          <StyledLink href={'#/settings/tenants'}>
            {t('settings.tenant.tenants')}
          </StyledLink>
          {crumbs.slice(0, -1).map((crumb, index) => (
            <StyledLink href={`#${crumb.href}`} key={index}>
              {t(crumb.crumb)}
            </StyledLink>
          ))}
          {crumbs.slice(-1).map((crumb, index) => (
            <span key={index}>{t(crumb.crumb)}</span>
          ))}
        </Breadcrumbs>
      </Block>
      <Outlet />
    </Block>
  )
})
