import { yupResolver } from '@hookform/resolvers/yup'
import { KIND } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal'
import { Machine } from 'client'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

const AddMachineDialog: React.FC<{
  isOpen: boolean
  onClose: (machine: Machine) => void
  onCancel: () => void
}> = React.memo(({ isOpen, onClose, onCancel }) => {
  const [t] = useTranslation()
  const { locationId } = useParams()
  const machineSchema: yup.ObjectSchema<Machine> = useMemo(() => {
    return yup.object({
      id: yup.number(),
      name: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.machine.name')),
      locationId: yup
        .number()
        .required(t('validation.required'))
        .label(t('settings.machine.location')),
      deviceId: yup
        .string()
        .required(t('validation.required'))
        .label(t('settings.machine.device_id')),
      tenantId: yup.string(),
    })
  }, [t])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm<Machine>({
    resolver: yupResolver(machineSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      locationId: locationId ? parseInt(locationId) : undefined,
      deviceId: '',
    },
  })

  return (
    <Modal isOpen={isOpen} closeable={false}>
      <form
        onSubmit={handleSubmit((machine: Machine) => {
          onClose(machine)
          reset()
        })}
      >
        <input type={'hidden'} {...register('locationId')} />
        <ModalHeader>{t('settings.machine.add_machine')}</ModalHeader>
        <ModalBody>
          <FormControl
            label={t('settings.machine.name')}
            error={errors?.name?.message}
          >
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.machine.name')}
                  value={value}
                  error={!!errors?.name}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
          <FormControl
            label={t('settings.machine.device_id')}
            error={errors?.deviceId?.message}
          >
            <Controller
              name={'deviceId'}
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  placeholder={t('settings.machine.device_id')}
                  value={value}
                  error={!!errors?.deviceId}
                  inputRef={createCallbackRef(ref)}
                />
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={KIND.tertiary}
            onClick={() => {
              onCancel()
              reset()
            }}
            type={'button'}
          >
            {t('cancel')}
          </ModalButton>
          <ModalButton type={'submit'}>{t('ok')}</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
})

export { AddMachineDialog }
