import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { LabelLarge, LabelMedium, LabelSmall } from 'baseui/typography'
import React, { memo, useCallback, useState } from 'react'

type StateCardProps = Readonly<{
  name: string
  description: string
  details: string[]
  statusOK: boolean | undefined
}>

export const StateCard: React.FC<StateCardProps> = memo(
  ({ name, description, details, statusOK }) => {
    const [isExpanded, setExpanded] = useState(false)

    const [css, theme] = useStyletron()

    const getCardColor = useCallback(
      (status?: boolean) => {
        if (status === true) {
          return theme.colors.positive300
        } else if (status === false) {
          return theme.colors.negative300
        } else {
          return theme.colors.mono300
        }
      },
      [theme.colors]
    )

    return (
      <div
        className={css({
          width: '100%',
          height: isExpanded ? '250px' : '100px',
          transition: 'height 0.25s ease-in-out',
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
          boxShadow: `0px 4px 8px rgba(0,0,0,0.2)`,
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: isExpanded ? '0px' : '5px',
            borderBottomRightRadius: isExpanded ? '0px' : '5px',
            backgroundColor: getCardColor(statusOK),
          })}
          onClick={() => setExpanded(!isExpanded)}
        >
          <LabelLarge
            className={css({
              flex: '1',
              textAlign: 'center',
              fontSize: '20px',
              color: statusOK ? 'black' : 'white',
            })}
          >
            {name}
          </LabelLarge>
          <Icon
            path={isExpanded ? mdiChevronUp : mdiChevronDown}
            size={1}
            color={statusOK ? 'black' : 'white'}
            className={css({
              paddingRight: theme.sizing.scale400,
            })}
          />
        </div>

        <div
          className={css({
            width: '100%',
            height: isExpanded ? '150px' : '0px',
          })}
        >
          <div
            className={css({
              visibility: isExpanded ? 'visible' : 'hidden',
              flexDirection: 'column',
              justifyContent: 'space-around',
              backgroundColor: 'white',
              paddingLeft: theme.sizing.scale600,
              paddingTop: theme.sizing.scale600,
              paddingRight: theme.sizing.scale600,
            })}
          >
            <LabelMedium>{description}</LabelMedium>
            <ul>
              {details.map((detail, idx) => (
                <li key={idx}>
                  <LabelSmall>{detail}</LabelSmall>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
)
