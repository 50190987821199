import { useStyletron } from 'baseui'
import { LabelLarge } from 'baseui/typography'
import React, { memo, useCallback, useMemo } from 'react'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'

type GaugeProps = Readonly<{
  header: string
  secondSectionStart?: number
  thirdSectionStart?: number
  maxValue: number
  progressValue: number | null
  unit: string
  reverseColor: boolean
  valueFormatOptions: Intl.NumberFormatOptions
}>

const RADIAN = Math.PI / 180

export const DataTacho: React.FC<GaugeProps> = memo(
  ({
    header,
    secondSectionStart,
    thirdSectionStart,
    maxValue,
    progressValue,
    unit,
    reverseColor,
    valueFormatOptions,
  }) => {
    const [css, theme] = useStyletron()

    const colors = useMemo(() => {
      const ret = [
        { fg: theme.colors.positive300, bg: theme.colors.positive100 },
        { fg: theme.colors.warning, bg: theme.colors.warning100 },
        { fg: theme.colors.negative, bg: theme.colors.negative100 },
      ]
      if (reverseColor) {
        ret.reverse()
      }
      return ret
    }, [reverseColor, theme])

    const dataAreas = useMemo(() => {
      const areas = [{ start: 0, end: secondSectionStart ?? maxValue }]
      if (secondSectionStart !== undefined) {
        areas.push({
          start: secondSectionStart,
          end: thirdSectionStart ?? maxValue,
        })
      }
      if (thirdSectionStart !== undefined) {
        areas.push({ start: thirdSectionStart, end: maxValue })
      }

      return areas.map((area, idx) => ({
        ...area,
        value: area.end - area.start,
        colors: colors[idx],
      }))
    }, [colors, maxValue, secondSectionStart, thirdSectionStart])

    const matchingArea = useMemo(() => {
      if (!isNullOrUndefined(progressValue)) {
        return dataAreas.find(
          ({ start, end }) => progressValue >= start && progressValue <= end
        )
      }
    }, [dataAreas, progressValue])
    const progressBarData = useMemo(
      () =>
        dataAreas
          .flatMap((area) => {
            if (isNullOrUndefined(progressValue)) {
              return [
                {
                  start: area.start,
                  end: area.end,
                  color: 'none',
                },
              ]
            } else if (area === matchingArea) {
              return [
                {
                  start: area.start,
                  end: progressValue,
                  color: area.colors.fg,
                },
                {
                  start: progressValue,
                  end: area.end,
                  color: area.colors.bg,
                },
              ]
            } else if (progressValue < area.start) {
              return [
                {
                  start: area.start,
                  end: area.end,
                  color: 'none',
                },
              ]
            } else {
              return [
                {
                  start: area.start,
                  end: area.end,
                  color: area.colors.fg,
                },
              ]
            }
          })
          .filter((d) => typeof d !== 'undefined')
          .map((data) => ({ ...data, value: data.end - data.start })),
      [dataAreas, matchingArea, progressValue]
    )

    const dataCircle = useMemo(
      () => [
        {
          name: 'Value',
          value: Number.EPSILON,
        },
        {
          name: 'Value',
          value: Number.EPSILON,
        },
      ],
      []
    )

    type RenderLabelProps = Readonly<{
      cx: number
      cy: number
      midAngle: number
      innerRadius: number
      outerRadius: number
      percent: number
      index: number
    }>
    const renderLabel = useCallback(
      (values: RenderLabelProps) => {
        if (values.index === 1) {
          const radius =
            values.innerRadius + (values.outerRadius - values.innerRadius) * 0.5
          const x = values.cx + radius * Math.cos(-values.midAngle * RADIAN)
          const y = values.cy + radius * Math.sin(-values.midAngle * RADIAN)
          const label = isNullOrUndefined(progressValue)
            ? '---'
            : Number(progressValue).toLocaleString(
                undefined,
                valueFormatOptions
              )
          return (
            <>
              <text
                x={x}
                y={y - 30}
                fill='black'
                textAnchor='middle'
                dominantBaseline='central'
              >
                {label}
              </text>
              <text
                x={x}
                y={y - 10}
                fill='black'
                textAnchor='middle'
                dominantBaseline='central'
              >
                {unit}
              </text>
            </>
          )
        }
      },
      [progressValue, unit, valueFormatOptions]
    )

    return (
      <>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            width: '280px',
            height: '300px',
            borderRadius: '5px',
            alignContent: 'end',
            justifySelf: 'center',
            boxShadow: `0px 4px 8px rgba(0,0,0,0.2)`,
            backgroundColor: 'white',
            marginBottom: theme.sizing.scale100,
          })}
        >
          <LabelLarge
            style={{
              display: 'flex',
              width: '100%',
              height: theme.sizing.scale1200,
              paddingTop: theme.sizing.scale400,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            {header}
          </LabelLarge>

          <ResponsiveContainer width='100%' height='80%'>
            <PieChart
              className={css({
                paddingTop: theme.sizing.scale400,
                paddingBottom: theme.sizing.scale100,
              })}
            >
              <Pie
                data={dataAreas}
                dataKey='value'
                cx='50%'
                cy='50%'
                innerRadius={60}
                outerRadius={80}
                startAngle={450}
                endAngle={90}
                fill='#8884d8'
                paddingAngle={0}
                isAnimationActive={false}
                label={({ cx, cy, startAngle, index }) => {
                  const radius = 100
                  const x = cx + radius * Math.cos(-startAngle * RADIAN)
                  const y = cy + radius * Math.sin(-startAngle * RADIAN)

                  switch (index) {
                    case 0:
                      return (
                        <text
                          x={x - 5}
                          y={y - 5}
                          fill='#000000'
                          textAnchor={'start'}
                          dominantBaseline='central'
                        >
                          {0}
                        </text>
                      )
                    case 1:
                      return (
                        <text
                          x={getXPosition(x, y)}
                          y={y < 160 && y > 40 ? y - 8 : y}
                          fill='#000000'
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline={
                            y < 160 && y > 60 ? 'right' : 'central'
                          }
                        >
                          {secondSectionStart}
                        </text>
                      )
                    case 2:
                      return (
                        <text
                          x={getXPosition(x, y)}
                          y={y < 160 && y > 40 ? y - 8 : y}
                          fill='#000000'
                          textAnchor={x > cx ? 'start' : 'end'}
                          dominantBaseline={
                            y < 160 && y > 60 ? 'right' : 'central'
                          }
                        >
                          {thirdSectionStart}
                        </text>
                      )
                  }
                }}
                labelLine={({ cx, cy, startAngle, innerRadius, index }) => {
                  const radius = innerRadius
                  const x = cx + radius * Math.cos(-startAngle * RADIAN)
                  const y = cy + radius * Math.sin(-startAngle * RADIAN)
                  const x2 = cx + (radius + 35) * Math.cos(-startAngle * RADIAN)
                  const y2 = cy + (radius + 35) * Math.sin(-startAngle * RADIAN)

                  return (
                    <line
                      key={`label-line-${index}`}
                      x1={x}
                      y1={y}
                      x2={x2}
                      y2={y2}
                      stroke='black'
                    />
                  )
                }}
              >
                {dataAreas.map((area) => (
                  <Cell key={area.start} fill={area.colors.bg} />
                ))}
                <Label fill='black' position='left' />
              </Pie>

              <Pie
                paddingAngle={0}
                data={progressBarData}
                dataKey='value'
                cx='50%'
                cy='50%'
                innerRadius={60}
                outerRadius={80}
                startAngle={450}
                endAngle={90}
              >
                {progressBarData.map((entry) => (
                  <Cell key={entry.start} fill={entry.color} />
                ))}
              </Pie>

              <Pie
                data={dataCircle}
                dataKey='value'
                nameKey='name'
                cx='50%'
                cy='50%'
                isAnimationActive={false}
                outerRadius={40}
                startAngle={0}
                endAngle={360}
                paddingAngle={0}
                label={renderLabel}
                labelLine={false}
                stroke={'none'}
                strokeWidth={0}
              >
                {dataCircle.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      matchingArea?.colors.fg ??
                      theme.colors.backgroundSecondary
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    )
  }
)

function getXPosition(x: number, y: number) {
  if (x < 130) {
    if (y < 160 && y > 40) {
      return x + 12
    } else {
      return x
    }
  } else {
    if (y < 160 && y > 40) {
      return x - 12
    } else {
      return x
    }
  }
}

function isNullOrUndefined<T>(
  value: T | null | undefined
): value is null | undefined {
  return typeof value === 'undefined' || value === null
}
