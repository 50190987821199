import { yupResolver } from '@hookform/resolvers/yup'
import { Block } from 'baseui/block'
import { Button, KIND } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Tab, Tabs } from 'baseui/tabs-motion'
import { HeadingXSmall } from 'baseui/typography'
import { Tenant } from 'client'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { createCallbackRef } from 'use-callback-ref'
import * as yup from 'yup'

import { useApi } from '../../../ApiProvider'
import { useLoadIndicator } from '../../load-indicator'
import { Locations } from '../locations/locations'

export const TenantDetails: React.FC<{ activeTab: 'details' | 'locations' }> =
  React.memo(({ activeTab }) => {
    const [tenant, setTenant] = useState<Tenant>()
    const [t] = useTranslation()
    const params = useParams()
    const { tenantApi } = useApi()
    const { startLoading, stopLoading } = useLoadIndicator()
    const navigate = useNavigate()

    const tenantSchema: yup.ObjectSchema<Tenant> = useMemo(() => {
      return yup.object({
        id: yup.string(),
        name: yup
          .string()
          .required(t('validation.required'))
          .label(t('settings.tenant.name')),
      })
    }, [t])

    const {
      control,
      handleSubmit,
      formState: { errors, isDirty, isValid },
      reset,
    } = useForm<Tenant>({
      resolver: yupResolver(tenantSchema),
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        name: '',
      },
      values: tenant,
    })

    useEffect(() => {
      if (params.tenantId) {
        startLoading()
        tenantApi
          .getTenant(params.tenantId)
          .then((response) => {
            setTenant(response.data)
          })
          .finally(stopLoading)
      }
    }, [tenantApi, params, startLoading, stopLoading])

    if (!tenant) {
      return null
    }

    return (
      <>
        <Block
          paddingTop={'scale600'}
          paddingRight={'scale600'}
          paddingLeft={'scale600'}
        >
          <HeadingXSmall marginTop={'scale300'} marginBottom={'scale600'}>
            {tenant.name}
          </HeadingXSmall>
        </Block>
        <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
          <Tabs
            activeKey={activeTab}
            onChange={({ activeKey }) => {
              if (activeKey !== activeTab) {
                navigate({ pathname: `../${activeKey}` })
              }
            }}
            activateOnFocus
            overrides={{
              Root: {
                style: {
                  flex: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
            }}
          >
            <Tab key={'details'} title={t('settings.tenant.details')}>
              <form
                onSubmit={handleSubmit((tenant: Tenant) => {
                  tenantApi
                    .updateTenant(tenant.id!, tenant)
                    .then((response) => {
                      setTenant(response.data)
                    })
                })}
              >
                <FormControl label={t('settings.tenant.id')}>
                  <Input placeholder={tenant.id} readOnly />
                </FormControl>
                <FormControl
                  label={t('settings.tenant.name')}
                  error={errors?.name?.message}
                >
                  <Controller
                    name={'name'}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={t('settings.tenant.name')}
                        value={value}
                        error={!!errors?.name}
                        inputRef={createCallbackRef(ref)}
                      />
                    )}
                  />
                </FormControl>
                <Button
                  disabled={!isValid || !isDirty}
                  type={'submit'}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        marginRight: $theme.sizing.scale600,
                      }),
                    },
                  }}
                >
                  Save
                </Button>
                <Button
                  disabled={!isDirty}
                  onClick={() => reset()}
                  kind={KIND.tertiary}
                  type={'reset'}
                >
                  Revert
                </Button>
              </form>
            </Tab>
            <Tab
              key={'locations'}
              title={t('settings.tenant.locations')}
              overrides={{
                TabPanel: {
                  style: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                  },
                },
              }}
            >
              <Locations />
            </Tab>
          </Tabs>
        </Block>
      </>
    )
  })
