import { faEllipsisVertical, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND, SIZE } from 'baseui/button'
import { StatefulMenu } from 'baseui/menu'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { Tenant } from 'client'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../../ApiProvider'
import { useLoadIndicator } from '../../load-indicator'
import { AddTenantDialog } from './add-tenant-dialog'

export const Tenants: React.FC = React.memo(() => {
  const [css, theme] = useStyletron()
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [showAddTenantDialog, setShowAddTenantDialog] = useState(false)
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'name',
    direction: 'ASC',
  })
  const [t] = useTranslation()
  const api = useApi()
  const { startLoading, stopLoading } = useLoadIndicator()
  const navigate = useNavigate()

  useEffect(() => {
    startLoading()
    api.tenantApi
      .listTenants()
      .then((result) => {
        setTenants(result.data)
      })
      .finally(() => {
        stopLoading()
      })
  }, [api.tenantApi, startLoading, stopLoading])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
        paddingBottom={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('navigation.settings')}</span>
          <span>{t('settings.tenant.tenants')}</span>
        </Breadcrumbs>
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            setShowAddTenantDialog(true)
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
          }}
        >
          {t('settings.tenant.add_tenant')}
        </Button>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={tenants.length}
              rowGetter={({ index }) => {
                return tenants[index]
              }}
              onRowClick={({ rowData, index }) => {
                navigate({ pathname: `${rowData.id}/details` })
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'inherit',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  })
                }
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setTenants(
                  tenants.slice().sort((a: any, b: any) => {
                    const valueA = a[sortBy]
                    const valueB = b[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.tenant.no_tenants')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.tenant.id')}
                dataKey={'id'}
                width={300}
                className={bodyCellClass}
                cellRenderer={({ cellData }) => {
                  return cellData
                }}
              />
              <Column
                label={t('settings.tenant.name')}
                dataKey={'name'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData }) => {
                  return cellData
                }}
              />
              <Column
                disableSort
                dataKey={'action'}
                width={90}
                className={bodyCellClass}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
                cellRenderer={({ rowData }) => {
                  return (
                    <StatefulPopover
                      placement={PLACEMENT.auto}
                      content={({ close }) => (
                        <StatefulMenu
                          items={[
                            {
                              label: t('settings.machine.delete'),
                              action: 'delete',
                            },
                          ]}
                          onItemSelect={({ item, event }) => {
                            event?.stopPropagation()
                            if (item.action === 'delete') {
                              api.tenantApi
                                .deleteTenant(rowData.id)
                                .then(() => {
                                  setTenants((prev) =>
                                    prev.filter(({ id }) => id !== rowData.id)
                                  )
                                })
                            }
                            close()
                          }}
                        />
                      )}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <Button
                        size={SIZE.mini}
                        kind={KIND.tertiary}
                        overrides={{
                          BaseButton: {
                            style: ({ $theme }) => ({
                              ':hover': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkHover,
                              },
                              ':focus': {
                                backgroundColor: 'transparent',
                              },
                              ':active': {
                                backgroundColor: 'transparent',
                                color: $theme.colors.linkActive,
                              },
                              ':disabled': {
                                backgroundColor: 'transparent',
                              },
                            }),
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          size={'lg'}
                          icon={faEllipsisVertical}
                        />
                      </Button>
                    </StatefulPopover>
                  )
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Block>
      <AddTenantDialog
        isOpen={showAddTenantDialog}
        onClose={(tenant) => {
          api.tenantApi.addTenant(tenant).then((response) => {
            setTenants((prev) => [...prev, response.data])
            setShowAddTenantDialog(false)
          })
        }}
        onCancel={() => {
          setShowAddTenantDialog(false)
        }}
      />
    </Block>
  )
})
