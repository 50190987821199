"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Schrader Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticErrorKey = void 0;
/**
 *
 * @export
 * @enum {string}
 */
exports.AnalyticErrorKey = {
    AVAILABILITY: 'AVAILABILITY',
    OFFLINE_CLEANING: 'OFFLINE_CLEANING',
    STOPPED: 'STOPPED',
    EVAPORATION_STARTPROCESS_ERROR: 'EVAPORATION_STARTPROCESS_ERROR',
    DROPLETSEPARATOR_DIFFERENTIAL_PRESSURE_HIGH: 'DROPLETSEPARATOR_DIFFERENTIAL_PRESSURE_HIGH',
    FINAL_CONCENTRATOR_DISCHARGE_BLOCKED: 'FINAL_CONCENTRATOR_DISCHARGE_BLOCKED',
    FINAL_CONCENTRATOR_NOT_ENOUGH_STEAM: 'FINAL_CONCENTRATOR_NOT_ENOUGH_STEAM',
    FINAL_CONCENTRATOR_BLOCKED: 'FINAL_CONCENTRATOR_BLOCKED',
    CONCENTRATE_TANK_DISCHARGE_BLOCKED: 'CONCENTRATE_TANK_DISCHARGE_BLOCKED',
    AMOUNT_OF_WATER_HIGH: 'AMOUNT_OF_WATER_HIGH',
    AMOUNT_OF_WATER_LOW: 'AMOUNT_OF_WATER_LOW',
    AUTOMATIC_FILTER_DIRTY: 'AUTOMATIC_FILTER_DIRTY',
    CLEAR_PHASE_PRODUCTION_LOW: 'CLEAR_PHASE_PRODUCTION_LOW',
    FEED_WASTE_WATER_LOW: 'FEED_WASTE_WATER_LOW',
    CONDENSATE_PRODUCTION_LOW: 'CONDENSATE_PRODUCTION_LOW',
    TEMPERATURE_DIFFERENCE_HIGH: 'TEMPERATURE_DIFFERENCE_HIGH',
    PRESSURE_DIFFERENCE_HIGH: 'PRESSURE_DIFFERENCE_HIGH',
    THROUGHPUT_ENDCONCENTRATOR_LOW: 'THROUGHPUT_ENDCONCENTRATOR_LOW',
    CONCENTRATE_DISPOSAL_LOW: 'CONCENTRATE_DISPOSAL_LOW',
    PRECONCENTRATOR_NOT_REFILLED: 'PRECONCENTRATOR_NOT_REFILLED',
    ONE_STEP_TAKES_TOO_LONG: 'ONE_STEP_TAKES_TOO_LONG',
    OFFLINE_CLEANING_STEP_TAKES_TOO_LONG: 'OFFLINE_CLEANING_STEP_TAKES_TOO_LONG',
    PSG1_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT: 'PSG1_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT',
    PSG2_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT: 'PSG2_STEAM_GENERATOR_PRESSURE_NOT_ON_SETPOINT'
};
