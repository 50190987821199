import { Tab, TabOverrides, Tabs } from 'baseui/tabs-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useScope } from '../utils'
import { AnalyticErrorsHistory } from './dashboard/analytic-errors-history'
import { Evaluation } from './dashboard/evaluation'
import { HeatExchangerDashboard } from './dashboard/heat-exchanger-dashboard'
import { KPI } from './dashboard/kpi'
import { LocationsOverview } from './dashboard/locations-overview'
import { State } from './dashboard/state'
import { Status } from './dashboard/status'
import {
  useAvailableMachines,
  useSelectedMachine,
  useSetSelectedMachine,
} from './layout/page'

export const Dashboard: React.FC = React.memo(() => {
  const { t } = useTranslation()
  const [activeKey, setActiveKey] = useState('0')
  const selectedMachine = useSelectedMachine()
  const availableMachines = useAvailableMachines()
  const setSelectedMachine = useSetSelectedMachine()
  const { hasScope } = useScope()

  const activeTabOverrides: TabOverrides = {
    TabPanel: {
      style: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  }

  if (!selectedMachine) {
    return (
      <LocationsOverview
        machines={availableMachines}
        setSelectedMachine={setSelectedMachine}
      />
    )
  } else {
    return (
      <Tabs
        overrides={{
          Root: {
            style: {
              flex: 'auto',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
        activeKey={activeKey}
        onChange={({ activeKey: selectedTab }) => {
          setActiveKey(selectedTab.toString())
        }}
        activateOnFocus
      >
        <Tab title={t('dashboard.status')}>
          <Status machine={selectedMachine} />
        </Tab>
        <Tab title={t('dashboard.evaluation')}>
          <Evaluation
            location={selectedMachine?.location}
            machine={selectedMachine}
          />
        </Tab>
        <Tab title={t('dashboard.kpi')}>
          <KPI location={selectedMachine?.location} machine={selectedMachine} />
        </Tab>
        {hasScope(['analytic_errors:read']) && (
          <Tab title={t('dashboard.heatexchanger')}>
            <HeatExchangerDashboard
              location={selectedMachine?.location}
              machine={selectedMachine}
            />
          </Tab>
        )}
        {hasScope(['analytic_errors:read']) && (
          <Tab
            key={'dashboard.state'}
            title={t('dashboard.state')}
            overrides={
              activeKey === 'dashboard.state' ? activeTabOverrides : {}
            }
          >
            <State machine={selectedMachine} />
          </Tab>
        )}
        {hasScope(['analytic_errors:read']) && (
          <Tab title={t('dashboard.analyticerrors.history')}>
            {selectedMachine && (
              <AnalyticErrorsHistory machine={selectedMachine} />
            )}
          </Tab>
        )}
      </Tabs>
    )
  }
})
