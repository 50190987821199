import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Calendar } from 'baseui/datepicker'
import { enUS } from 'date-fns/locale'
import React, { useState } from 'react'

const isValidRange = (range: Date | Date[]) => {
  return (
    Array.isArray(range) &&
    range.length === 2 &&
    range[0] !== undefined &&
    range[1] !== undefined &&
    range[1].getTime() > range[0].getTime()
  )
}

export const DateChooser: React.FC<{
  value: [Date, Date] | []
  close: () => void
  onSelect: (range: [Date, Date]) => void
}> = React.memo(({ value, close, onSelect }) => {
  const [range, setRange] = useState<Date | Date[]>(value)

  return (
    <Block
      display={'flex'}
      flexDirection={'column'}
      backgroundColor={'calendarBackground'}
    >
      <Calendar
        value={range}
        locale={enUS}
        range
        maxDate={new Date()}
        onChange={({ date }) => {
          if (date !== null) {
            if (Array.isArray(date)) {
              if (
                date[0] !== undefined &&
                date[0] !== null &&
                date[1] !== undefined &&
                date[1] !== null
              ) {
                if (date[1].getTime() > new Date().getTime()) {
                  date[1] = new Date()
                }
                if (date[1].getTime() < date[0].getTime()) {
                  setRange([date[1], date[0]])
                } else {
                  setRange([date[0], date[1]])
                }
              } else {
                setRange(date as Date[])
              }
            } else {
              setRange(date as Date)
            }
          }
        }}
        overrides={{
          TimeSelect: {
            props: {
              format: '12',
              size: 'compact',
              step: 3600,
              creatable: true,
            },
          },
        }}
      />
      <Button
        size={'compact'}
        disabled={!isValidRange(range)}
        onClick={() => {
          close()
          if (range !== undefined) {
            onSelect(range as [Date, Date])
          }
        }}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              marginLeft: $theme.sizing.scale600,
              marginRight: $theme.sizing.scale600,
              marginBottom: $theme.sizing.scale600,
            }),
          },
        }}
      >
        OK
      </Button>
    </Block>
  )
})
