import {
  AnalyticErrorsApi,
  Configuration,
  KpiApi,
  LocationApi,
  MachineApi,
  ReportApi,
  SensorApi,
  StatusApi,
  TenantApi,
  UsersApi,
} from 'client'
import React, { createContext, useContext, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

export type ApiT = {
  locationApi: LocationApi
  machineApi: MachineApi
  sensorApi: SensorApi
  kpiApi: KpiApi
  statusApi: StatusApi
  analyticErrApi: AnalyticErrorsApi
  reportApi: ReportApi
  usersApi: UsersApi
  tenantApi: TenantApi
}

export const ApiContext = createContext<ApiT>({
  locationApi: new LocationApi(),
  machineApi: new MachineApi(),
  sensorApi: new SensorApi(),
  kpiApi: new KpiApi(),
  statusApi: new StatusApi(),
  analyticErrApi: new AnalyticErrorsApi(),
  reportApi: new ReportApi(),
  usersApi: new UsersApi(),
  tenantApi: new TenantApi(),
})

export const ApiProvider: React.FC<{
  configuration?: Configuration
}> = ({ configuration, children }) => {
  const auth = useAuth()
  const configurationWithToken = useMemo(
    () =>
      new Configuration({
        ...configuration,
        accessToken: () => auth.user?.access_token || '',
      }),
    [configuration, auth]
  )
  const api = useMemo(() => {
    return {
      locationApi: new LocationApi(configurationWithToken),
      machineApi: new MachineApi(configurationWithToken),
      sensorApi: new SensorApi(configurationWithToken),
      kpiApi: new KpiApi(configurationWithToken),
      statusApi: new StatusApi(configurationWithToken),
      analyticErrApi: new AnalyticErrorsApi(configurationWithToken),
      reportApi: new ReportApi(configurationWithToken),
      usersApi: new UsersApi(configurationWithToken),
      tenantApi: new TenantApi(configurationWithToken),
    }
  }, [configurationWithToken])
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): ApiT => {
  return useContext(ApiContext)
}
