import {
  faCheck,
  faPen,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { mdiFormTextboxPassword } from '@mdi/js'
import Icon from '@mdi/react'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { Breadcrumbs } from 'baseui/breadcrumbs'
import { Button, KIND } from 'baseui/button'
import { ButtonGroup } from 'baseui/button-group'
import { StatefulCheckbox } from 'baseui/checkbox'
import { StatefulInput } from 'baseui/input'
import { StyledLink } from 'baseui/link'
import { StatefulSelect } from 'baseui/select'
import { Tag } from 'baseui/tag'
import { Tenant, User } from 'client'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { AutoSizer, Column, SortDirectionType, Table } from 'react-virtualized'

import { useApi } from '../../../ApiProvider'
import { useLoadIndicator } from '../../load-indicator'
import { AddUserDialog } from './add-user-dialog'
import { PasswordDialog } from './password-dialog'

const sortRoles = (a: any, b: any) => {
  if (b === 'user') {
    return 1
  } else {
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }
    return 0
  }
}

const Users: React.FC = React.memo(() => {
  const [css, theme] = useStyletron()
  const [users, setUsers] = useState<User[]>([])
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [editing, setEditing] = useState(false)
  const [valid, setValid] = useState(true)
  const [showAddUserDialog, setShowAddUserDialog] = useState(false)
  const [showPasswordDialog, setShowPasswordDialog] = useState(false)
  const [sort, setSort] = useState<{
    column: string
    direction: SortDirectionType
  }>({
    column: 'lastName',
    direction: 'ASC',
  })
  const record = useRef<User>()
  const [t] = useTranslation()
  const api = useApi()
  const auth = useAuth()
  const { startLoading, stopLoading } = useLoadIndicator()

  const updateUser = useCallback(
    (user: User) => {
      if (user.id !== undefined) {
        api.usersApi.updateUser(user.id, user).then((result) => {
          setUsers(
            users.map(({ id }, index) => {
              if (id === user.id) {
                return { ...result.data, isNew: false }
              } else {
                return users[index]
              }
            })
          )
          record.current = undefined
          setEditing(false)
        })
      }
    },
    [api.usersApi, users]
  )

  const deleteUser = useCallback(
    (user: User) => {
      if (user.id !== undefined) {
        api.usersApi.deleteUser(user.id).then(() => {
          setUsers(users.filter(({ id }) => id !== user.id))
        })
      }
    },
    [api.usersApi, users]
  )

  const startEdit = useCallback((user: User) => {
    record.current = user
    setEditing(true)
  }, [])

  const cancelEdit = useCallback(() => {
    record.current = undefined
    setEditing(false)
    if (!valid) {
      setValid(true)
    }
  }, [valid])

  const commitEdit = useCallback(() => {
    if (editing && record.current !== undefined) {
      updateUser(record.current)
    }
  }, [editing, updateUser])

  const isEditing = useCallback(
    (rowIndex: number) => {
      return (
        editing &&
        record.current !== undefined &&
        record.current.id === users[rowIndex].id
      )
    },
    [editing, users]
  )

  useEffect(() => {
    startLoading()
    Promise.all([api.usersApi.listUsers(), api.tenantApi.listTenants()])
      .then(([userResponse, tenantResponse]) => {
        setUsers(userResponse.data)
        setTenants(tenantResponse.data)
      })
      .finally(() => {
        stopLoading()
      })
  }, [api.usersApi, api.tenantApi, startLoading, stopLoading])

  const bodyCellClass = useMemo(
    () =>
      css({
        ...theme.typography.ParagraphSmall,
        ...theme.borders.border300,
        borderTop: 'none',
        borderBottom: 'none',
        [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: 'none',
        borderColor: 'transparent',
        color: theme.colors.contentPrimary,
        paddingTop: theme.sizing.scale300,
        paddingRight: theme.sizing.scale600,
        paddingBottom: theme.sizing.scale300,
        paddingLeft: theme.sizing.scale600,
        ':last-of-type': {
          [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: 'none',
        },
      }),
    [css, theme]
  )

  return (
    <Block flex={'auto'} display={'flex'} flexDirection={'column'}>
      <Block
        paddingTop={'scale600'}
        paddingRight={'scale600'}
        paddingLeft={'scale600'}
        paddingBottom={'scale600'}
      >
        <Breadcrumbs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingBottom: $theme.sizing.scale600,
              }),
            },
          }}
        >
          <span>{t('navigation.settings')}</span>
          <span>{t('settings.manageAccounts')}</span>
        </Breadcrumbs>
        <Button
          size={'compact'}
          startEnhancer={() => <FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            setShowAddUserDialog(true)
          }}
          overrides={{
            StartEnhancer: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale300,
              }),
            },
          }}
        >
          {t('settings.add_user')}
        </Button>
      </Block>
      <Block flex={'auto'}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              height={height}
              width={width}
              headerHeight={44}
              rowHeight={36}
              rowCount={users.length}
              rowGetter={({ index }) => {
                return users[index]
              }}
              rowClassName={({ index }) => {
                if (index === -1) {
                  return css({
                    backgroundColor: theme.colors.tableHeadBackgroundColor,
                    boxShadow: theme.lighting.shadow400,
                    display: 'flex',
                    alignItems: 'center',
                  })
                } else {
                  return css({
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isEditing(index)
                      ? theme.colors.backgroundTertiary
                      : 'inherit',
                  })
                }
              }}
              headerClassName={css({
                ...theme.typography.LabelMedium,
                ...theme.borders.border300,
                borderTop: 'none',
                borderBottom: 'none',
                [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']:
                  'none',
                color: theme.colors.contentPrimary,
                paddingTop: theme.sizing.scale500,
                paddingRight: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale500,
                paddingLeft: theme.sizing.scale600,
                ':focus': {
                  outline: 'none',
                },
                ':nth-last-of-type(2)': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
                ':last-of-type': {
                  [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']:
                    'none',
                },
              })}
              gridClassName={css({
                ':focus': {
                  outline: 'none',
                },
              })}
              className={css({
                backgroundColor: theme.colors.tableBackground,
                display: 'flex',
                flexDirection: 'column',
              })}
              sortBy={sort.column}
              sortDirection={sort.direction}
              sort={({ sortBy, sortDirection }) => {
                setSort({
                  column: sortBy,
                  direction: sortDirection,
                })
                setUsers(
                  users.slice().sort((a: any, b: any) => {
                    const valueA = a[sortBy]
                    const valueB = b[sortBy]
                    if (valueA < valueB) {
                      return sortDirection === 'ASC' ? -1 : 1
                    }
                    if (valueA > valueB) {
                      return sortDirection === 'ASC' ? 1 : -1
                    }
                    return 0
                  })
                )
              }}
              noRowsRenderer={() => {
                return (
                  <div
                    className={css({
                      ...theme.typography.ParagraphMedium,
                      color: theme.colors.contentPrimary,
                      textAlign: 'center',
                      paddingTop: theme.sizing.scale800,
                    })}
                  >
                    {t('settings.no_users')}
                  </div>
                )
              }}
            >
              <Column
                label={t('settings.first_name')}
                dataKey={'firstName'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.firstName')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.firstName = e.currentTarget.value
                          }
                        }}
                        size={'compact'}
                        autoFocus={true}
                        overrides={{
                          Root: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          InputContainer: {
                            style: {
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.last_name')}
                dataKey={'lastName'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.lastName')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.lastName = e.currentTarget.value
                          }
                        }}
                        size={'compact'}
                        overrides={{
                          Root: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          InputContainer: {
                            style: {
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.email')}
                dataKey={'email'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulInput
                        placeholder={t('settings.email')}
                        initialState={{ value: cellData }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.email = e.currentTarget.value
                          }
                        }}
                        size={'compact'}
                        overrides={{
                          Root: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          InputContainer: {
                            style: {
                              backgroundColor: 'transparent',
                            },
                          },
                          Input: {
                            style: {
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                  }
                }}
              />
              <Column
                label={t('settings.roles')}
                dataKey={'roles'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex, rowData }) => {
                  if (
                    isEditing(rowIndex) &&
                    record.current !== undefined &&
                    auth.user?.profile?.sub !== rowData['id']
                  ) {
                    return (
                      <StatefulSelect
                        options={[
                          { id: 'user', label: t('settings.user') },
                          { id: 'admin', label: t('settings.admin') },
                        ]}
                        initialState={{
                          value: cellData.map((id: any) => ({ id })),
                        }}
                        onChange={({ value }) => {
                          if (record.current !== undefined) {
                            record.current.roles = value.map(
                              ({ id }) => id as string
                            )
                            record.current.roles.sort(sortRoles)
                          }
                        }}
                        placeholder={t('settings.roles')}
                        clearable={false}
                        searchable={false}
                        deleteRemoves={false}
                        backspaceRemoves={false}
                        multi
                        size={'compact'}
                        overrides={{
                          Tag: {
                            component: (props: any) => {
                              return (
                                <Tag
                                  {...props}
                                  closeable={props.value.id !== 'user'}
                                  overrides={{
                                    Root: {
                                      style: {
                                        marginLeft: 0,
                                      },
                                    },
                                  }}
                                />
                              )
                            },
                          },
                          ControlContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          ValueContainer: {
                            style: {
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                          IconsContainer: {
                            style: {
                              paddingRight: 0,
                            },
                          },
                          SingleValue: {
                            style: {
                              marginLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return cellData
                      .slice()
                      .sort(sortRoles)
                      .map((id: any) => t(`settings.${id}`))
                      .join(', ')
                  }
                }}
              />
              <Column
                label={t('settings.tenant.tenant')}
                dataKey={'tenantId'}
                width={150}
                flexGrow={1}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex }) => {
                  if (isEditing(rowIndex) && record.current !== undefined) {
                    return (
                      <StatefulSelect
                        options={tenants}
                        initialState={{
                          value: [{ id: cellData }],
                        }}
                        onChange={({ value }) => {
                          if (record.current !== undefined) {
                            record.current.tenantId = value[0].id as string
                          }
                        }}
                        placeholder={t('settings.roles')}
                        clearable={false}
                        searchable={false}
                        deleteRemoves={false}
                        backspaceRemoves={false}
                        size={'compact'}
                        labelKey={'name'}
                        overrides={{
                          Tag: {
                            component: (props: any) => {
                              return (
                                <Tag
                                  {...props}
                                  closeable={props.value.id !== 'user'}
                                  overrides={{
                                    Root: {
                                      style: {
                                        marginLeft: 0,
                                      },
                                    },
                                  }}
                                />
                              )
                            },
                          },
                          ControlContainer: {
                            style: {
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: 'transparent',
                            },
                          },
                          ValueContainer: {
                            style: {
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                            },
                          },
                          IconsContainer: {
                            style: {
                              paddingRight: 0,
                            },
                          },
                          SingleValue: {
                            style: {
                              marginLeft: 0,
                            },
                          },
                        }}
                      />
                    )
                  } else {
                    return (
                      <StyledLink
                        href={`#/settings/tenants/${cellData}`}
                        animateUnderline
                      >
                        {tenants.find(({ id }) => id === cellData)?.name ??
                          cellData}
                      </StyledLink>
                    )
                  }
                }}
              />
              <Column
                label={t('settings.enabled')}
                dataKey={'enabled'}
                width={70}
                className={bodyCellClass}
                cellRenderer={({ cellData, rowIndex, rowData }) => {
                  if (
                    isEditing(rowIndex) &&
                    record.current !== undefined &&
                    auth.user?.profile?.sub !== rowData['id']
                  ) {
                    return (
                      <StatefulCheckbox
                        initialState={{
                          checked: cellData,
                        }}
                        onChange={(e) => {
                          if (record.current !== undefined) {
                            record.current.enabled = e.currentTarget.checked
                          }
                        }}
                      />
                    )
                  } else {
                    return cellData ? t('yes') : t('no')
                  }
                }}
              />
              <Column
                disableSort
                dataKey={'action'}
                width={90}
                className={bodyCellClass}
                cellRenderer={({ rowIndex, rowData }) => {
                  if (!editing || isEditing(rowIndex)) {
                    return (
                      <ButtonGroup
                        size={'mini'}
                        kind={KIND.tertiary}
                        overrides={{
                          Root: {
                            style: {
                              justifyContent: 'end',
                            },
                          },
                        }}
                      >
                        {!editing && (
                          <Button
                            overrides={{
                              BaseButton: {
                                style: ({ $theme }) => ({
                                  ':hover': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkHover,
                                  },
                                  ':focus': {
                                    backgroundColor: 'transparent',
                                  },
                                  ':active': {
                                    backgroundColor: 'transparent',
                                    color: $theme.colors.linkActive,
                                  },
                                  ':disabled': {
                                    backgroundColor: 'transparent',
                                  },
                                }),
                              },
                            }}
                            onClick={() => {
                              record.current = users[rowIndex]
                              setShowPasswordDialog(true)
                            }}
                          >
                            <Icon
                              path={mdiFormTextboxPassword}
                              size={'18px'}
                              title={t('settings.reset_password')}
                            />
                          </Button>
                        )}
                        <Button
                          disabled={!valid}
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              commitEdit()
                            } else {
                              startEdit({ ...users[rowIndex] })
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faCheck : faPen}
                            title={
                              isEditing(rowIndex)
                                ? t('settings.save')
                                : t('settings.edit')
                            }
                          />
                        </Button>
                        <Button
                          disabled={
                            !isEditing(rowIndex) &&
                            auth.user?.profile?.sub === rowData['id']
                          }
                          overrides={{
                            BaseButton: {
                              style: ({ $theme }) => ({
                                ':hover': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkHover,
                                },
                                ':focus': {
                                  backgroundColor: 'transparent',
                                },
                                ':active': {
                                  backgroundColor: 'transparent',
                                  color: $theme.colors.linkActive,
                                },
                                ':disabled': {
                                  backgroundColor: 'transparent',
                                },
                              }),
                            },
                          }}
                          onClick={() => {
                            if (isEditing(rowIndex)) {
                              cancelEdit()
                            } else {
                              deleteUser(users[rowIndex])
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            size={'lg'}
                            icon={isEditing(rowIndex) ? faTimes : faTrash}
                            title={
                              isEditing(rowIndex)
                                ? t('settings.cancel')
                                : t('settings.delete')
                            }
                          />
                        </Button>
                      </ButtonGroup>
                    )
                  } else {
                    return null
                  }
                }}
              />
            </Table>
          )}
        </AutoSizer>
      </Block>
      <AddUserDialog
        isOpen={showAddUserDialog}
        onClose={(user) => {
          api.usersApi.addUser(user).then((response) => {
            setUsers((prev) => [...prev, response.data])
            setShowAddUserDialog(false)
          })
        }}
        onCancel={() => {
          setShowAddUserDialog(false)
        }}
      />
      <PasswordDialog
        user={record.current!}
        isOpen={record.current !== undefined && showPasswordDialog}
        onClose={(user) => {
          if (user.id !== undefined) {
            api.usersApi.updateUser(user.id, user).then((response) => {
              setShowPasswordDialog(false)
              record.current = undefined
            })
          } else {
            setShowPasswordDialog(false)
            record.current = undefined
          }
        }}
        onCancel={() => {
          setShowPasswordDialog(false)
          record.current = undefined
        }}
      />
    </Block>
  )
})

export { Users }
